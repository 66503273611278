import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../screens/Dashboard";
import LoginScreen from "../screens/LoginScreen";
import IncomeScreen from "../screens/IncomeScreen";
import AllLeads from "../screens/AllLeads";
import ReqPayout from "../screens/ReqPayout";
import TotalAccount from "../screens/TotalAccount";
import TotalTradedIncome from "../screens/TotalTradedIncome";

const AllRoutes = [
  { path: "/login", component: <LoginScreen /> },
  { path: "/console", component: <Dashboard /> },
  { path: "/IncomeScreen", component: <IncomeScreen /> },
  { path: "/AllLeads", component: <AllLeads /> },
  { path: "/ReqPayout", component: <ReqPayout /> },
  { path: "/dd", component: <TotalAccount /> },
  { path: "/totalTradedIncome", component: <TotalTradedIncome /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  { path: "*", component: <Navigate to="/login" /> },
  { path: "*", component: <Navigate to="/console" /> },
  { path: "*", component: <Navigate to="/IncomeScreen" /> },
  { path: "*", component: <Navigate to="/AllLeads" /> },
  { path: "*", component: <Navigate to="/ReqPayout" /> },
  { path: "*", component: <Navigate to="/totalAccount" /> },
  { path: "*", component: <Navigate to="/totalTradedIncome" /> },
];

const publicroute = [
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  { path: "/", component: <LoginScreen /> },
  { path: "/login", component: <LoginScreen /> },
]

export { AllRoutes, publicroute };

import { API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_FAIL, API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_SUCCESS, API_INCOME_GET_BROKREAGE_LIST_FAIL, API_INCOME_GET_BROKREAGE_LIST_SUCCESS, GET_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED, GET_INCOME_GET_BROKREAGE_LIST, RESET_TRADE_LEADS } from "./actionType";

export const allLeadsApiResponseSuccess = (actionType, data) => ({
  type: API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_SUCCESS,
  payload: { actionType, data },
});

export const allLeadsApiResponseError = (actionType, error) => ({
  type: API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_FAIL,
  payload: { actionType, error },
});

export const getAllLeadsListApi = (data) => ({
  type: GET_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED,
  payload: data,
});

export const resetTradeIncome = () => ({
  type: RESET_TRADE_LEADS,
});
import {
  GET_STAGEWISE_UNCONVERTED_LEADS,
  API_STAGEWISE_UNCONVERTED_LEADS_SUCCESS,
  API_STAGEWISE_UNCONVERTED_LEADS_FAIL,
  STAGEWISE_STEPS_DATA,
} from "./actionType";

export const stageWiseApiResponseSuccess = (actionType, data) => ({
  type: API_STAGEWISE_UNCONVERTED_LEADS_SUCCESS,
  payload: { actionType, data },
});

export const stageWiseApiResponseError = (actionType, error) => ({
  type: API_STAGEWISE_UNCONVERTED_LEADS_FAIL,
  payload: { actionType, error },
});

export const getStagewiseUnconvertedLeads = (data) => ({
  type: GET_STAGEWISE_UNCONVERTED_LEADS,
  payload: data,
});

export const stageWiseStepsData = (data) => ({
  type: STAGEWISE_STEPS_DATA,
  data,
});

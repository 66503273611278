import {
  API_LEAD_SUMMARY_CONV_UNCONV_SUCCESS,
  API_LEAD_SUMMARY_CONV_UNCONV_FAIL,
  GET_LEAD_SUMMARY_CONV_UNCONV,
} from "./actionType";

const INIT_STATE = {
  leadSummaryData: {},
};
const leadSummaryConvUnconv = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_LEAD_SUMMARY_CONV_UNCONV_SUCCESS:
      switch (action.payload.actionType) {
        case GET_LEAD_SUMMARY_CONV_UNCONV:
          return {
            ...state,
            leadSummaryData:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_LEAD_SUMMARY_CONV_UNCONV_FAIL:
      switch (action.payload.actionType) {
        case GET_LEAD_SUMMARY_CONV_UNCONV:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_LEAD_SUMMARY_CONV_UNCONV: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default leadSummaryConvUnconv;

import { SET_USER_ID } from "./actionType";

const INIT_STATE = {
  userId: [],
};

const userIdData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload ? action.payload : [],
      };

    default:
      return { ...state };
  }
};

export default userIdData;

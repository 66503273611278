import { GET_LOGIN, API_LOGIN_SUCCESS, API_LOGIN_FAIL, REMOVE_LOGIN_DATA, API_OTP_FAIL, GET_OTP, REMOVE_OTP_DATA, API_OTP_SUCCESS } from "./actionType";

export const otpApiResponseSuccess = (actionType, data) => ({
  type: API_OTP_SUCCESS,
  payload: { actionType, data },
});

export const otpApiResponseError = (actionType, error) => ({
  type: API_OTP_FAIL,
  payload: { actionType, error },
});

export const postOTP = (data) => ({
  type: GET_OTP,
  payload: data,
});

export const removeOTPData = () => ({
  type: REMOVE_OTP_DATA,
});
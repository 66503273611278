import { API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_FAIL, API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_SUCCESS, API_INCOME_GET_BROKREAGE_LIST_FAIL, API_INCOME_GET_BROKREAGE_LIST_SUCCESS, GET_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED, RESET_TRADE_LEADS } from "./actionType";

const INIT_STATE = {
  getTotalAccountOpned: [],
};

const AllLeads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED:
          return {
            ...state,
            getTotalAccountOpned:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_FAIL:
      switch (action.payload.actionType) {
        case GET_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED: {
      return {
        ...state,
      };
    }
    case RESET_TRADE_LEADS:
      return {
        ...state,
        getTotalAccountOpned: [], // Reset the state here
      };
    default:
      return { ...state };
  }
};

export default AllLeads;

import { API_INCOME_GET_BROKREAGE_LIST_FAIL, API_INCOME_GET_BROKREAGE_LIST_SUCCESS, CLIENT_WISE_INCOME, GET_INCOME_GET_BROKREAGE_LIST } from "./actionType";

  const INIT_STATE = {
    ClientWiseincomeList: [],
  };
  
  const BrokerageListIncomePage = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_INCOME_GET_BROKREAGE_LIST_SUCCESS:
        switch (action.payload.actionType) {
          case GET_INCOME_GET_BROKREAGE_LIST:
            return {
              ...state,
              BrokrageList:
                action.payload.data.data !== null ? action.payload.data.data : [],
            };
          default:
            return { ...state };
        }
      case API_INCOME_GET_BROKREAGE_LIST_FAIL:
        switch (action.payload.actionType) {
          case GET_INCOME_GET_BROKREAGE_LIST:
            return {
              ...state,
              error: action.payload.error,
            };
          default:
            return { ...state };
        }
      case GET_INCOME_GET_BROKREAGE_LIST: {
        return {
          ...state,
        };
      }
      case CLIENT_WISE_INCOME: // Handle the EMPTY_TOTAL_EARNING action
      return {
        ...state,
        ClientWiseincomeList: [] // Set totalEarning to an empty array
      };
      default:
        return { ...state };
    }
  };
  
  export default BrokerageListIncomePage;
  
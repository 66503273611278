import { call, put, takeEvery, all, fork, retry } from "redux-saga/effects";

import { GET_LOGIN, GET_OTP, START_LOADING, STOP_LOADING } from "./actionType";

import { loginApiResponseError, loginApiResponseSuccess, otpApiResponseError, otpApiResponseSuccess } from "./action";

import { postLoginApi, postOtpApi } from "../../api/fakebackend_helper";

function* OtpDetails(data) {
  try {
    yield put({ type: START_LOADING });
    const response = yield call(postOtpApi, data.payload);
    let check = response?.data
    response?.success && localStorage.setItem("userData", JSON.stringify(check))
    yield put(otpApiResponseSuccess(GET_OTP, response));
    yield put({ type: STOP_LOADING });
  } catch (error) {
    yield put({ type: STOP_LOADING });
    yield put(otpApiResponseError(GET_OTP, error));
  }
}

export function* watchGetCart() {
  // yield put({ type: START_LOADING });
  yield takeEvery(GET_OTP, OtpDetails);
}

function* OtpDetailsSaga() {
  yield all([fork(watchGetCart)]);
}

export default OtpDetailsSaga;

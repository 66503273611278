import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AllRoutes, publicroute } from "./allRoutes";
import { data } from "jquery";

const Index = () => {

  const data = localStorage.getItem("userData");
  return (
    <React.Fragment>
      <Routes>
        <Route>

          {AllRoutes?.map((route, idx) => (
            <Route
              path={route.path}
              element={route.component}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;

import React from "react";
import "./IncomeScreen.css";
import { ImagePath } from "../helper/ImagePath";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import CustomCalendar from "../components/CustomCalendar";
import CustomPopup from "../components/CustomPopup";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { last3Months } from "../helper/helpingMethods";
import { useEffect } from "react";

function ReqPayout() {
  const [entries, setEntries] = useState("10")
  const [startDate, setStartDate] = useState(dayjs(last3Months))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  //>>>>>>>>serach input table data >>>
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  //>>>>>>>>>Pagination state used
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  // menun icon selected value
  const [checkedItems, setCheckedItems] = useState({});

  const options = [
    { id: "created_at", label: "Latest created date" },
    { id: "updated_at", label: "Last updated date" },
    // { id: "status", label: "Status" },
  ];
  const [filterQuery, setFilterQuery] = useState("");
  const [isModalClose, setIsmodalClose] = useState(false);

  const isCheckboxChecked =
    checkedItems.created_at || checkedItems.status || checkedItems.updated_at;
  const handleFilterSearch = (e) => {
    const query = e.target.value;
    setFilterQuery(query);
  };
  const popupView = () => {
    return (
      <div className="popup_body ">
        <div className="search_bar">
          <form>
            <input
              // value={query}
              disabled={!isCheckboxChecked}
              className="form-control me-2"
              type="search"
              placeholder="Search..."
              aria-label="Search"
              onChange={(e) => handleFilterSearch(e)}
            />
            <img
              className="img img-fluid"
              src={ImagePath.search}
              alt="search"
            />
          </form>
        </div>

        <div className="mt-4">
          <Form className="filter_cechbox">
            {options.map((option) => (
              <Form.Check
                key={option.id}
                type="checkbox"
                id={option.id}
                label={option.label}
                checked={checkedItems[option.id] || false}
                onChange={handleCheckboxChange}
              />
            ))}
          </Form>
        </div>
      </div>
    );
  };

  const clickableView = () => {
    return <img src={ImagePath.filterIcon} alt="filter" />;
  };
  // entries selected
  const handleSelectChange = (event) => {
    const selectedEntries = event.target.value;
    setEntriesPerPage(selectedEntries);
    setCurrentPage(1);
  };
  // date select start end date
  const onChangeStartDate = (date) => {
    setStartDate(date.$d);
  };
  const onChangeToDate = (date) => {
    setEndDate(date.$d);
  };
  // search input field enter data filter
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    // Filter the data based on the search term
    const filtered = filteredData && filteredData.filter(item => item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredData(filtered);
  }, [searchTerm]);

  //>>>>>>>>>pagination funcrtion are used
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //>>>>>>>>>><<<<<<<<<check box function
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckedItems(id)
  };
  const handleRecivedData = (receivedData) => {
    setIsmodalClose(!receivedData);
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container main-dive">
        <div className="row  mt-4">
          <div className="d-flex monthly_cat_select">
            <h4>Request Payout</h4>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4 col-md-6 mb-3">
              <div className="total_ac_card req_payout_wr">
                <div className="d-flex ms-3 card_lines">
                  <span className="reqPayout_num">₹ 1,50,567</span>
                  <span className="card_text">Total Earned</span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-3">
              <div className="total_ac_card req_payout_wr">
                <div className="d-flex ms-3 card_lines">
                  <span className="reqPayout_num">₹ 18,567</span>
                  <span className="card_text">Total Paid Out</span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-3">
              <div className="total_ac_card req_payout_wr">
                <div className="d-flex ms-3 card_lines">
                  <span className="reqPayout_num">₹ 1,32,567</span>
                  <span className="card_text">Available for Withdrawal</span>
                </div>
              </div>
            </div>
          </div>

          <div className="monthly_cat_select mt-4">
            <span className="withdraw_text">Withdraw Balance</span>
            {/* <div className="row"> */}
            <div className="col-md-6 amout_search_wr mt-3">
              <div className="amout_inpute_box d-flex">
                <input
                  type="text"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Enter amount"
                  className="custom-input"
                />
                <button
                  className="custom-button"
                // onClick={handleButtonClick}
                >
                  <div className="share_wr">
                    <span>Withdraw</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-4 mb-5">
          <div className="clien_wise_text">
            <div className=" d-flex monthly_cat_select ">
              <div className="d-flex p-2">
                <h4>Withdrawal History</h4>
              </div>
              <div className="d-flex col-md-4 show_ertry_wr">
                <div className="show_text">Show</div>
                <div className="lead_filter  d-flex">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={entriesPerPage}
                    onChange={handleSelectChange}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                  </select>
                </div>
                {/* <div className="show_text">{entries}</div> */}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-7">
                <div className="search_bar search_name_no">
                  <input
                    className="me-2"
                    type="search"
                    placeholder="Search by Name, Mobile Numbe"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  // placeholder="Enter amount"
                  // className="custom-input"
                  />
                  <img
                    className="img img-fluid"
                    src={ImagePath.search}
                    alt="search"
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="d-flex calendar_wr">
                  <span>Select Date</span>
                  <div className="date_picker_wr ">
                    <div className="date_picker me-1">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From Date"
                          format="DD/MM/YYYY"
                          value={startDate}
                          onChange={onChangeStartDate}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="date_picker ms-1 me-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To Date"
                          format="DD/MM/YYYY"
                          value={endDate}
                          onChange={onChangeToDate}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>



                  <div>
                    <div className="mt-4">
                      <CustomPopup
                        onModalOpenedDataReceived={handleRecivedData}
                        content={popupView()}
                        onClickView={clickableView()}
                        value="filter"
                      />                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="client_wise_income_table_wr ">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Transaction ID</th>
                    <th cope="col">Date</th>
                    <th cope="col">Name</th>
                    <th cope="col">Amount (₹)</th>
                    <th cope="col">Amount (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((item) => {
                    return (
                      <tr>
                        <td>{item?.transationId}</td>
                        <td>{item?.date}</td>
                        <td>{item?.name}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.amountDone}</td>
                      </tr>
                    )
                  })}


                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="table_footer d-flex"> */}
          <div className="table_footer d-flex">
            <span>{`Showing ${indexOfFirstEntry + 1} to ${Math.min(
              indexOfLastEntry,
              filteredData.length
            )} of ${filteredData.length} entries`}</span>
          
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default ReqPayout;

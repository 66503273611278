import { all, fork } from "redux-saga/effects";

//VERIFY_OTP
import TotalEarningSaga from "./TotalEarning/saga";
import ConvUnconvLeadsSaga from "./MonthwiseConUnconLeads/saga";
import LeadSummaryConvUnconvSaga from "./LeadSummaryConvUnconv/saga";
import StagewiseUnconvLeadsSaga from "./StagewiseUnconvertedLeads/saga";
import ReferralLinkSaga from "./ReferralLink/saga";
import LoginDetailsSaga from "./LoginApi/saga";
import userIdSaga from "./UserCode/saga";

import AllLeadsSaga from "./allLeadsPage/getTotalAccountOpned/saga"
import ClientListSaga from "./InComePage/getClientListApi/saga";
import BrokrageListSaga from "./InComePage/getBrokrageListApi/saga"
import MonthlyOpnedTradeSaga from "./MonthlyOpnedTrade/saga";
import AllLeadsUnCOnvertedSaga from "./allLeadsPage/getAllUnConverted/saga"
//delete
import RecommendedAlgosSaga from "./RecommendedAlgo/saga";
import OtpDetailsSaga from "./otpPage/saga";
export default function* rootSaga() {

  yield all([fork(RecommendedAlgosSaga)]);
  yield all([fork(BrokrageListSaga)]);
  yield all([fork(ClientListSaga)]);
  yield all([fork(AllLeadsSaga)]);
  yield all([fork(MonthlyOpnedTradeSaga)]);
  yield all([fork(AllLeadsUnCOnvertedSaga)]);
  yield all([fork(OtpDetailsSaga)]);

  yield all([fork(TotalEarningSaga)]);
  yield all([fork(ConvUnconvLeadsSaga)]);
  yield all([fork(LeadSummaryConvUnconvSaga)]);
  yield all([fork(StagewiseUnconvLeadsSaga)]);
  yield all([fork(ReferralLinkSaga)]);
  yield all([fork(LoginDetailsSaga)]);
  yield all([fork(userIdSaga)]);

}

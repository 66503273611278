
import React, { useMemo } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { ImagePath } from "../helper/ImagePath";
import { json, useNavigate } from "react-router-dom";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function TotalLeadsChartDashboard({
  converted,
  unconverted,
}) {
  const Navigate = useNavigate();

  const options = useMemo(() => {
    const handleClick = (e) => {
      let clickedElementLabel = e?.dataPoint?.name;
      if (clickedElementLabel === "Converted" || clickedElementLabel === "Unconverted") {
        Navigate("/AllLeads", { state: { clickedElementLabel } });
      }
    };

    return {
      animationEnabled: true,
      height: 280,
      title: {
        text: "Total Leads",
        verticalAlign: "center",
        fontSize: 16,
        fontColor: "#9599AD",
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: {
          top: -50,
        },
      },
      subtitles: [
        {
          text: `${converted + unconverted}`,
          verticalAlign: "center",
          fontSize: 24,
          dockInsidePlotArea: true,
          fontColor: "#545A6D",
          fontWeight: 500,
          fontFamily: "Poppins",
          padding: {
            top: 30,
          },
        },
      ],
      data: [
        {
          type: "doughnut",
          showInLegend: false,
          yValueFormatString: "#,###",
          radius: "100%",
          innerRadius: "78%",
          click: handleClick,
          dataPoints: [
            { name: "Converted", y: converted, color: "#29BADB" },
            { name: "Unconverted", y: unconverted, color: "#FFDE2D" },
          ],
        },
      ],
    };
  }, [converted, unconverted, Navigate]);

  return (
    <div className="row">
      <div className="">
        {(unconverted === 0 && converted === 0) ? (
          <div className="nodata_chart">
            <p>No data available</p>
          </div>
        ) : (
          <div>
            <CanvasJSChart options={options} />
            <div className="d-flex justify-content-around mt-3">
              <p className="mb-1">
                {" "}
                <img src={ImagePath.converted} alt="unconverted" /> Converted : {converted}{" "}
              </p>
              <p className="mb-1">
                {" "}
                <img src={ImagePath.unconvertedIcon} alt="unconverted" /> Unconverted : {unconverted}{" "}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { API_INCOME_GET_BROKREAGE_LIST_FAIL, API_INCOME_GET_BROKREAGE_LIST_SUCCESS, CLIENT_WISE_INCOME, GET_INCOME_GET_BROKREAGE_LIST } from "./actionType";

export const BrokerageListApiResponseSuccess = (actionType, data) => ({
  type: API_INCOME_GET_BROKREAGE_LIST_SUCCESS,
  payload: { actionType, data },
});

export const BrokerageListApiResponseError = (actionType, error) => ({
  type: API_INCOME_GET_BROKREAGE_LIST_FAIL,
  payload: { actionType, error },
});

export const getClientWiseIncomeListApi = (data) => ({
  type: GET_INCOME_GET_BROKREAGE_LIST,
  payload: data,
});

export const emptyClientEarning = () => ({
  type: CLIENT_WISE_INCOME,
});
import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_STAGEWISE_UNCONVERTED_LEADS, START_LOADING, STOP_LOADING } from "./actionType";

import {
  stageWiseApiResponseError,
  stageWiseApiResponseSuccess,
} from "./action";

import { postStagewiseUnconvertedLeads } from "../../api/fakebackend_helper";

function* stageWiseUnconv(data) {
  try {
    // yield put({ type: START_LOADING });
    const response = yield call(postStagewiseUnconvertedLeads, data.payload);
    yield put(
      stageWiseApiResponseSuccess(GET_STAGEWISE_UNCONVERTED_LEADS, response)
    );
    // yield put({ type: STOP_LOADING });
  } catch (error) {
    // yield put({ type: STOP_LOADING });
    yield put(
      stageWiseApiResponseError(GET_STAGEWISE_UNCONVERTED_LEADS, error)
    );
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_STAGEWISE_UNCONVERTED_LEADS, stageWiseUnconv);
}

function* StagewiseUnconvLeadsSaga() {
  yield all([fork(watchGetCart)]);
}

export default StagewiseUnconvLeadsSaga;

import React, { useState } from "react";
import "../screens/IncomeScreen.css";

const ToggleButton = (props) => {
  const [toggleValue, settoggleValue] = useState("income");

  const handleIncomeClientToggle = (name) => {
    settoggleValue(name);
    props.onModalOpenedDataReceived(name);
  };

  return (
    <div className="income_client_switch_wr">
      {toggleValue === "income" ? (
        <button
          className="income_button_first ative_button"
          onClick={() => handleIncomeClientToggle("income")}
        >
          <div className="client_wr income_wr_first ative_button_text">
            <span>Business</span>
          </div>
        </button>
      ) : (
        <button
          className="income_button_first deative_button"
          onClick={() => handleIncomeClientToggle("income")}
        >
          <div className="client_wr income_wr_first deactive_btn_text">
            <span>Business</span>
          </div>
        </button>
      )}

      {toggleValue === "client" ? (
        <button
          className=" income_button_first ative_button"
          onClick={() => handleIncomeClientToggle("client")}
        >
          <div className="client_wr ative_button_text">
            <span>Clients</span>
          </div>
        </button>
      ) : (
        <button
          className="income_button_first deative_button"
          onClick={() => handleIncomeClientToggle("client")}
        >
          <div className="client_wr deactive_btn_text">
            <span>Clients</span>
          </div>
        </button>
      )}
    </div>
  );
};
export default ToggleButton;

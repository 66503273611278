import React, { useState } from "react";
import "../screens/IncomeScreen.css";
import "../screens/Dashboard.css";
import DatePicker from "react-datepicker";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { ImagePath } from "../helper/ImagePath";

function CustomCalendar({ placeholderName }) {
  const [selectedDate, setSelectedDate] = useState(null);

  const CustomInput = ({ value, onClick }) => (
    <div className="custom-input" onClick={onClick}>
      <img src={ImagePath.calendarIcon} alt="calendar" />
      <span className="calander_placeholder_text">
        {value || placeholderName}
      </span>
    </div>
  );
  return (
    <div className="calendar_icon_body">
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        customInput={<CustomInput />}
        dateFormat="MM/dd/yyyy"
        popperPlacement="bottom-start"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "-5px, 10px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
          },
        }}
      />
    </div>
  );
}
export default CustomCalendar;

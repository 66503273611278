import { API_INCOME_GET_CLIENT_LIST_FAIL, API_INCOME_GET_CLIENT_LIST_SUCCESS, API_MONTHLY_OPNED_TRADE_FAIL, API_MONTHLY_OPNED_TRADE_SUCCESS, GET_INCOME_GET_CLIENT_LIST, GET_MONTHLY_OPNED_TRADE_LIST } from "./actionType";

export const MonthlyOpnedTradeApiResponseSuccess = (actionType, data) => ({
  type: API_MONTHLY_OPNED_TRADE_SUCCESS,
  payload: { actionType, data },
});

export const MonthlyOpnedTradeApiResponseError = (actionType, error) => ({
  type: API_MONTHLY_OPNED_TRADE_FAIL,
  payload: { actionType, error },
});

export const getMonthlyOpnedTradeApi = (data) => ({
  type: GET_MONTHLY_OPNED_TRADE_LIST,
  payload: data,
});
import React, { useState, useEffect, useRef } from "react";
import "./CustomPopup.css";
import { ImagePath } from "../helper/ImagePath";

const CustomPopup = (props) => {
  const content = props.content;
  const onClickView = props.onClickView;
  const polyColor = props.polyColor;

  const [isVisible, setIsVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const modalRef = useRef(null);

  const openModal = () => {
    setIsVisible(true);
    setIsClicked(!isClicked);
    sendModalOpenedDataToParent(true);
  };

  const closeModal = () => {
    setIsVisible(false);
    sendModalOpenedDataToParent(false);
  };

  const sendModalOpenedDataToParent = (value) => {
    props.onModalOpenedDataReceived(value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        isClicked &&
        !modalRef.current.contains(event.target)
      ) {
        openModal();
      } else if (
        modalRef.current &&
        !isClicked &&
        !modalRef.current.contains(event.target)
      ) {
        closeModal();
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isClicked]);

  return (
    <div className="popup-container">
      <div
        className="popup-trigger"
        onClick={() => (isVisible ? closeModal() : openModal())}
      >
        {onClickView}
      </div>
      {isVisible && (
        <>
          {polyColor === "blue" ? (
            <div className="popup-content" ref={modalRef}>
              <img className="poly_icon" src={ImagePath.bluePolygon} />
              {content}
            </div>
          ) : (
            <div className="popup-content" ref={modalRef}>
              <img
                className="white_polygone_position"
                src={ImagePath.polygonIcon}
              />
              {content}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomPopup;

import { API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_FAIL, API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_SUCCESS, API_ALLLEADS_GET_UNCONVERTED_LIST_FAIL, API_ALLLEADS_GET_UNCONVERTED_LIST_SUCCESS, API_INCOME_GET_BROKREAGE_LIST_FAIL, API_INCOME_GET_BROKREAGE_LIST_SUCCESS, GET_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED, GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED, GET_INCOME_GET_BROKREAGE_LIST } from "./actionType";

export const allLeadsUnconvertedListApiResponseSuccess = (actionType, data) => ({
    type: API_ALLLEADS_GET_UNCONVERTED_LIST_SUCCESS,
    payload: { actionType, data },
  });
  
  export const allLeadsUnconvertedListApiResponseError = (actionType, error) => ({
    type: API_ALLLEADS_GET_UNCONVERTED_LIST_FAIL,
    payload: { actionType, error },
  });
  
  export const getAllLeadsUnconvertedListApi = (data) => ({
    type: GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED,
    payload: data,
  });
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const TotalAccount = () => {
  const [status, setStatus] = useState('0')

  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    // Check if it's not the first render
    if (!isFirstRender.current && status === "0") {
      alert(';;s');
      const duration = "current_month"
      const uniqueCode = "RE-09182"
      const link = "client"

      // dispatch(
      //   getRefferlLInkCount({
      //     code: uniqueCode,
      //     link_type: link
      //   })
      // );
    }

    isFirstRender.current = false;
  }, [status]);

  return (
    <div>
      <h4>dsdsds</h4>
    </div>
  )
}
export default React.memo(TotalAccount);
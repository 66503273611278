import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from 'react-apexcharts';
import { useNavigate } from "react-router-dom";

export default function IncomeOverViewPieChart({
    BrokerageIncome,
    SubscriptionIncome,
    BroSUbTotal,
}) {
    const brokarage = BrokerageIncome ? parseFloat(BrokerageIncome.replace(/,/g, '')) : "";
    const subscription = SubscriptionIncome ? parseFloat(SubscriptionIncome.replace(/,/g, '')) : "";
    const parsedBrokarage = BrokerageIncome ? parseFloat(BrokerageIncome.replace(/,/g, '')) : "";
    const parsedBROsUBtOTAL = BroSUbTotal ? parseFloat(BroSUbTotal.replace(/,/g, '')) : "";
    const parsedSubscription = SubscriptionIncome ? parseFloat(SubscriptionIncome.replace(/,/g, '')) : "";

    const brokaragePercentage = parseFloat(BroSUbTotal && brokarage !== "" ? (parsedBrokarage / parsedBROsUBtOTAL) * 100 : "");
    const subscriptionPercentage = parseFloat(BroSUbTotal && subscription !== "" ? (parsedSubscription / parsedBROsUBtOTAL) * 100 : "");
    const Navigate = useNavigate();
    const handleChartClick = (event, chartContext, config) => {
        Navigate("/IncomeScreen")
    };
    const [chartData, setChartData] = useState({
        series: [brokarage, subscription],
        options: {
            colors: ["#29BADB", "#FFDE2D"],
            chart: {
                type: 'donut',
            },

            labels: ["Business Points", "Subscription Points"],
            responsive: [{
                breakpoint: 400,
                options: {
                    chart: {
                        width: 180
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            onClick: { handleChartClick },
            legend: {
                show: false,
            },

        },
    });

    useEffect(() => {
        setChartData({

            ...chartData,
            series: [brokarage, subscription],
        });
    }, [BrokerageIncome, SubscriptionIncome, BroSUbTotal, brokaragePercentage, subscriptionPercentage]);

    return (
        <div className="chart-container" >
            <ReactApexChart options={chartData?.options} series={chartData?.series} type="donut" width="330" onClick={handleChartClick} />
            <div className="d-flex justify-content-around mt-3">
                <div className="income-box mb-1">
                    <div className="income-icon unconverted-icon"></div>
                    <span>Subscription Points</span>
                </div>
                <div className="income-box mb-1">
                    <div className="income-icon converted-icon"></div>
                    <span>Business Points</span>
                </div>
            </div>
        </div>
    );
}



import React from 'react';
import "./pagination.css";
const Pagintion = ({ currentPage, totalPages, paginate, listLength, itemsPerPage }) => {
    // const pageRange = 2; // Number of pages to initially show
    // const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
    // const startIdx = Math.max(0, currentPage - Math.floor(pageRange / 2));
    // const endIdx = Math.min(totalPages - 1, startIdx + pageRange - 1);
    // const visiblePages = pageNumbers.slice(startIdx, endIdx + 1);
    const pageRange = 5;
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage + 1;
    const indexOfLastItem = Math.min(currentPage * itemsPerPage, listLength);
    const pageItemCount = indexOfLastItem - indexOfFirstItem + 1;

    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
    const startIdx = Math.max(0, currentPage - Math.floor(pageRange / 2));
    const endIdx = Math.min(totalPages - 1, startIdx + pageRange - 1);

    const visiblePages = pageNumbers.slice(startIdx, endIdx + 1);
    return (
        <div className="table-pagination-row">
            <div className="pagination-info">{`${indexOfLastItem ? indexOfLastItem : 0} out of ${listLength ? listLength : 0} entries`}</div>
            <div className="table-pagination">
                <div className="pagination-buttons">
                    <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className={currentPage === 1 ? 'inactive' : 'active'}>
                        &#8249;
                    </button>
                    {startIdx > 1 && (
                        <>
                            <button onClick={() => paginate(1)} className={currentPage === 1 ? 'active-page' : 'inactive-page'}>
                                1
                            </button>
                            {startIdx > 2 && <span>......</span>}
                        </>
                    )}
                    {visiblePages.map((page) => (
                        <button
                            key={page}
                            onClick={() => paginate(page)}
                            className={currentPage === page ? 'active-page' : 'inactive-page'}
                        >
                            {page}
                        </button>
                    ))}
                    {endIdx < totalPages - 1 && (
                        <>
                            {endIdx < totalPages - 2 && <span>...</span>}
                            <button onClick={() => paginate(totalPages)} className={currentPage === totalPages ? 'active-page' : 'inactive-page'}>
                                {totalPages}
                            </button>
                        </>
                    )}
                    <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'inactive' : 'active'}>
                        &#8250;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Pagintion;

import { GET_LOGIN, API_LOGIN_SUCCESS, API_LOGIN_FAIL, REMOVE_LOGIN_DATA } from "./actionType";

export const loginApiResponseSuccess = (actionType, data) => ({
  type: API_LOGIN_SUCCESS,
  payload: { actionType, data },
});

export const loginApiResponseError = (actionType, error) => ({
  type: API_LOGIN_FAIL,
  payload: { actionType, error },
});

export const postLogin = (data) => ({
  type: GET_LOGIN,
  payload: data,
});

export const removeLoginData = () => ({
  type: REMOVE_LOGIN_DATA,
});
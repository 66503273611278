import { API_INCOME_GET_CLIENT_LIST_FAIL, API_INCOME_GET_CLIENT_LIST_SUCCESS, GET_INCOME_GET_CLIENT_LIST } from "./actionType";

  const INIT_STATE = {
    SubScriptionWiseList: [],
  };
  
  const cLIENTListIncomePage = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_INCOME_GET_CLIENT_LIST_SUCCESS:
        switch (action.payload.actionType) {
          case GET_INCOME_GET_CLIENT_LIST:
            return {
              ...state,
              SubScriptionWiseList:
                action.payload.data.data !== null ? action.payload.data.data : [],
            };
          default:
            return { ...state };
        }
      case API_INCOME_GET_CLIENT_LIST_FAIL:
        switch (action.payload.actionType) {
          case GET_INCOME_GET_CLIENT_LIST:
            return {
              ...state,
              error: action.payload.error,
            };
          default:
            return { ...state };
        }
      case GET_INCOME_GET_CLIENT_LIST: {
        return {
          ...state,
        };
      }
      default:
        return { ...state };
    }
  };
  
  export default cLIENTListIncomePage;
  
import "./App.css";
import React, { useEffect } from "react";
import Route from "./Routes";
import Header from "./screens/Header";
import { json, useLocation, useNavigate } from "react-router-dom";
import GlobalLoader from "./components/GlobalLoader";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "./store/actions";
import { Dashboard, Login } from "@mui/icons-material";
import LoginScreen from "./screens/LoginScreen";
import Index from "./Routes";

function App() {
  const pathName = useLocation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  return (
    <React.Fragment>
      <GlobalLoader />
      {userData == null ? "" : (pathName.pathname === "/" || pathName.pathname === "/login") ? null : <Header />
      }
      {userData == null ? <LoginScreen /> : <Index />}
    </React.Fragment >
  );
}

export default App;


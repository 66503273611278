import {
  API_RECOMMENDED_ALGOS_SUCCESS,
  API_RECOMMENDED_ALGOS_FAIL,
  GET_RECOMMENDED_ALGOS,
  START_LOADING,
  STOP_LOADING,
} from "./actionType";

const INIT_STATE = {
  recommendedAlgosData: [],
  isLoading: false,
};

const recommendedAlgosDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case API_RECOMMENDED_ALGOS_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RECOMMENDED_ALGOS:
          return {
            ...state,
            recommendedAlgosData:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_RECOMMENDED_ALGOS_FAIL:
      switch (action.payload.actionType) {
        case GET_RECOMMENDED_ALGOS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_RECOMMENDED_ALGOS: {
      return {
        ...state,
      };
    }
    case STOP_LOADING:
      return { ...state, isLoading: false };
    default:
      return { ...state };
  }
};

export default recommendedAlgosDetails;

import { API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_FAIL, API_ALLLEADS_GET_TOTAL_ACCOUNT_OPNED_SUCCESS, API_ALLLEADS_GET_UNCONVERTED_LIST_FAIL, API_ALLLEADS_GET_UNCONVERTED_LIST_SUCCESS, API_INCOME_GET_BROKREAGE_LIST_FAIL, API_INCOME_GET_BROKREAGE_LIST_SUCCESS, GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED } from "./actionType";

const INIT_STATE = {
  getUnConvertedLIst: [],
};

const AllLeadsUnconvertedList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_ALLLEADS_GET_UNCONVERTED_LIST_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED:
          return {
            ...state,
            getUnConvertedLIst:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_ALLLEADS_GET_UNCONVERTED_LIST_FAIL:
      switch (action.payload.actionType) {
        case GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default AllLeadsUnconvertedList;

import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_INCOME_GET_BROKREAGE_LIST } from "./actionType";



import { BrokerageListApiResponseError, BrokerageListApiResponseSuccess } from "./action";
import { postBrokrageListIncomePage } from "../../../api/fakebackend_helper";

function* Brokrage(data) {
    try {
        const response = yield call(postBrokrageListIncomePage, data.payload);

        yield put(
            BrokerageListApiResponseSuccess(GET_INCOME_GET_BROKREAGE_LIST, response)
        );
    } catch (error) {
        yield put(BrokerageListApiResponseError(GET_INCOME_GET_BROKREAGE_LIST, error));
    }
}

export function* watchGetCart() {
    yield takeEvery(GET_INCOME_GET_BROKREAGE_LIST, Brokrage);
}

function* BrokrageListSaga() {
    yield all([fork(watchGetCart)]);
}

export default BrokrageListSaga;

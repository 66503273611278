// // import React from "react";
// // import {
// //   Chart as ChartJS,
// //   CategoryScale,
// //   LinearScale,
// //   BarElement,
// //   Title,
// //   Tooltip,
// //   Legend,
// // } from "chart.js";
// // import { Bar } from "react-chartjs-2";
// // import faker from "faker";
// // import CanvasJSReact from "@canvasjs/react-charts";
// // var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// // export default function MonthWiseLeadsChart() {
// //   const options = {
// //     height: 200,
// //     legend: {
// //       horizontalAlign: "bottom", // "center" , "right"
// //       // verticalAlign: "bottom", // "top" , "bottom"
// //       fontSize: 15,
// //     },
// //     axisX: {
// //       lineThickness: 1,
// //       tickThickness: 0,
// //       title: "Month",
// //       titleFontColor: "#7E7E7E",
// //       titleFontSize: 14,
// //       titleFontFamily: "Poppins",
// //       titleFontWeight: 400,
// //       // margin: 20,
// //       stripLines: [
// //         {
// //           value: 50,
// //           color: "transparent",
// //           label: "Dummy Long Label",
// //           labelMaxWidth: 100,
// //           labelPlacement: "outside",
// //           labelBackgroundColor: "transparent",
// //           labelFontColor: "transparent",
// //         },
// //       ],
// //     },
// //     axisY: {
// //       gridThickness: 0,
// //       title: "Income",
// //       titleFontSize: 14,
// //       titleFontFamily: "Poppins",
// //       titleFontColor: "#7E7E7E",
// //       titleFontWeight: 400,
// //       tickLength: 0,
// //       // interval: 500,
// //       viewportMinimum: 0,
// //       viewportMaximum: 1550,
// //     },

// //     toolTip: {
// //       content: "{name}: {y}",
// //     },
// //     dataPointWidth: 20,
// //     data: [
// //       {
// //         type: "bar",
// //         showInLegend: true,
// //         legendText: "Silver",
// //         color: "silver",
// //         dataPoints: [
// //           { x: 10, y: 166, label: "Italy" },
// //           { x: 15, y: 144, label: "China" },
// //           { x: 20, y: 223, label: "France" },
// //           { x: 25, y: 272, label: "Great Britain" },
// //           { x: 30, y: 319, label: "Soviet Union" },
// //           { x: 35, y: 759, label: "USA" },
// //         ],
// //       },
// //       {
// //         type: "bar",
// //         showInLegend: true,
// //         legendText: "Bronze",
// //         color: "#DCA978",
// //         dataPoints: [
// //           { x: 11, y: 166, label: "Italy" },
// //           { x: 16, y: 144, label: "China" },
// //           { x: 21, y: 223, label: "France" },
// //           { x: 26, y: 272, label: "Great Britain" },
// //           { x: 31, y: 319, label: "Soviet Union" },
// //           { x: 36, y: 759, label: "USA" },
// //         ],
// //       },
// //     ],
// //   };
// //   return <CanvasJSChart options={options} />;
// // }

// import React, { useState } from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   Legend,
//   CartesianGrid,
// } from "recharts";
// import DeviceWidth from "../helper/DeviceWidth";
// import { useSelector } from "react-redux";
// import { getMonths } from "../helper/helpingMethods";

// let data;
// const MonthWiseLeadsChart = () => {
//   const widthPX = DeviceWidth();
//   const chartWidth = widthPX - widthPX * 0.25;

//   const { monthWiseLeads } = useSelector((state) => ({
//     monthWiseLeads: state.monthwiseConvUnconvLeads,
//   }));

//   const monthWiseLeadsList = monthWiseLeads.monthwiseConvUnconvLeads;
//   const monthWiseLeadsCount = Object.keys(monthWiseLeadsList).length;

//   const extractUnconvertedValue = (key) => {
//     const entry = monthWiseLeadsList[key];
//     if (entry && entry.length > 0) {
//       return entry.map((item) => item.unconverted).join(", ");
//     } else {
//       return 0;
//     }
//   };

//   const extractConvertedValue = (key) => {
//     const entry = monthWiseLeadsList[key];
//     if (entry && entry.length > 0) {
//       return entry.map((item) => item.converted).join(", ");
//     } else {
//       return 0;
//     }
//   };

//   if (monthWiseLeadsCount !== 0) {
//     data = Object.keys(monthWiseLeadsList).map((key) => ({
//       name: getMonths(key),
//       Converted: extractConvertedValue(key),
//       Unconverted: extractUnconvertedValue(key),
//     }));
//   }

//   return (
//     <BarChart width={chartWidth} height={250} data={data}>
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="name" />
//       <YAxis />
//       <Tooltip />
//       <Legend />
//       <Bar dataKey="Converted" fill="#5367FC" />
//       <Bar dataKey="Unconverted" fill="#0A3294" />
//     </BarChart>
//   );
// };

// export default MonthWiseLeadsChart;


import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import DeviceWidth from "../helper/DeviceWidth";
import { useSelector } from "react-redux";
import { getMonths } from "../helper/helpingMethods";
import loaderImg from '../../src/assets/gif/pattern.gif';

const MonthWiseLeadsChart = () => {
  const widthPX = DeviceWidth();
  const chartWidth = widthPX - widthPX * 0.25;

  const { monthWiseLeads } = useSelector((state) => ({
    monthWiseLeads: state.monthwiseConvUnconvLeads,
  }));

  const monthWiseLeadsList = monthWiseLeads.monthwiseConvUnconvLeads;
  const data = Object.keys(monthWiseLeadsList).map((key) => ({
    name: getMonths(key),
    Converted: monthWiseLeadsList[key].converted,
    Unconverted: monthWiseLeadsList[key].unconverted,
  }));
  const reversedData = [...data].reverse()
  const Loader = () => (
    <div className="loader-container">
      <img src={loaderImg} alt="Loading..." className="loader-img" style={{ width: '150px', height: 'auto' }} />
    </div>
  );
  const formatYAxisTicks = (value) => {
    return Math.floor(value) === value ? value : '';
  };
  return (
    <>
      {reversedData.length == 0 ? <Loader /> :
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={reversedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxisTicks} domain={[0, 'auto']} />

            <Tooltip />
            <Legend />
            <Bar dataKey="Converted" fill="#5367FC" />
            <Bar dataKey="Unconverted" fill="#0A3294" />
          </BarChart>
        </ResponsiveContainer>
      }
    </>
  );
};

export default MonthWiseLeadsChart;

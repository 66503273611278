import React from "react";
import { connect, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import loader from '../../src/assets/gif/pattern.gif';

const GlobalLoader = () => {
  const { isLoadingValue } = useSelector((state) => ({
    isLoadingValue: state.isLoading,
  }));
  return isLoadingValue.isLoading ? (
    <div className="global-loader">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingValue.isLoading}
      >
        <img
          src={loader}
          alt="Loading..."
          className='site-loader-img'
          style={{
            "width": "150px",
            "height": "auto",
            "margin": "0 auto",
            "display": "block",
            "left": "50%",
            "top": "50%",
            "position": "absolute",
            "transform": "translate(-50%, -50%)"
          }} />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(GlobalLoader);

import {
  GET_MONTHWISE_CONV_UNCONV_LEADS,
  API_MONTHWISE_CONV_UNCONV_LEADS_SUCCESS,
  API_MONTHWISE_CONV_UNCONV_LEADS_FAIL,
  START_LOADING,
  STOP_LOADING,
  RESET_MONTHWISE_LEADS,
} from "./actionType";

export const monthWiseApiResponseSuccess = (actionType, data) => ({
  type: API_MONTHWISE_CONV_UNCONV_LEADS_SUCCESS,
  payload: { actionType, data },
});

export const monthWiseApiResponseError = (actionType, error) => ({
  type: API_MONTHWISE_CONV_UNCONV_LEADS_FAIL,
  payload: { actionType, error },
});

export const getMonthwiseConvUnconvLeads = (data) => ({
  type: GET_MONTHWISE_CONV_UNCONV_LEADS,
  payload: data,
});
export const resetMonthwiseLeads = () => ({
  type: RESET_MONTHWISE_LEADS,
});
import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_TOTAL_EARNING, START_LOADING, STOP_LOADING } from "./actionType";

import { earningApiResponseError, earningApiResponseSuccess } from "./action";

import { getTotalEarningApi, postGetToalEaring } from "../../api/fakebackend_helper";

function* Earning(data) {
  try {
    // yield put({ type: START_LOADING });

    const response = yield call(postGetToalEaring, data.payload);
    yield put(earningApiResponseSuccess(GET_TOTAL_EARNING, response));
    // yield put({ type: STOP_LOADING });

  } catch (error) {
    // yield put({ type: STOP_LOADING });

    yield put(earningApiResponseError(GET_TOTAL_EARNING, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_TOTAL_EARNING, Earning);
}

function* TotalEarningSaga() {
  yield all([fork(watchGetCart)]);
}

export default TotalEarningSaga;

import React, { useState } from "react";
import "./CarouselDashboard.css";
import { ImagePath } from "../helper/ImagePath";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const CarouselDashboard = () => {
    const updateState = () => {
        localStorage.setItem("Slider", JSON.stringify("0"));
    }

    return (
        <>
            <div class="open-account-popup">
                <a href="#" class="close-btn"><img src={ImagePath.CloseIcon} onClick={() => { updateState() }} /></a>
                <a href="#" onClick={updateState}>
                    <ReactOwlCarousel className='owl-theme' loop margin={10} items={1} autoplay >
                        <div className="item">

                            <img src={ImagePath.sliderBgOne} className="back" />
                            <div className="text">
                                <h4>Start your Investment Journey Today</h4>
                                <a href="#" className="btun">Apply Now</a>
                            </div>
                            <div className="image">
                                <img src={ImagePath.sliderOne} />
                            </div>
                        </div>
                        <div className="item blue-back">
                            <img src={ImagePath.sliderBgTwo} className="back" />
                            <div className="text">
                                <h4>Start your Investment Journey Today</h4>
                                <a href="#" className="btun">Apply Now</a>
                            </div>
                            <div className="image">
                                <img src={ImagePath.sliderTwo} />
                            </div>
                        </div>
                        <div className="item yellow-back">
                            <img src={ImagePath.sliderBgOne} className="back" />
                            <div className="text">
                                <h4>Start your Investment Journey Today</h4>
                                <a href="#" className="btun">Apply Now</a>
                            </div>
                            <div className="image">
                                <img src={ImagePath.sliderThree} />
                            </div>
                        </div>
                    </ReactOwlCarousel>
                </a>
            </div>
        </>
    );
};
export default CarouselDashboard;

import { addDays, subMonths, subYears, format, startOfMonth } from "date-fns";
import dayjs from "dayjs";
import { useSelector } from "react-redux";


// export const uniqueCode = "H005947";
// export const uniqueCode = "B100079";
// export const uniqueCode = "B101204";
// export const uniqueCode = "B101204";



export const setUserCode = (code) => {
  localStorage.setItem("Name", code);
};

export const getUserCode = () => {
  const abc = localStorage.getItem("Name");
  return abc;
};

export const getMonths = (date) => {
  const dateParts = date.split("-");
  const month = dateParts[1];
  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthAbbreviation = monthAbbreviations[parseInt(month, 10) - 1];
  return monthAbbreviation;
};

export const mobileNumberWrapper = (phoneNumber) => {
  const maskedPhoneNumber = phoneNumber.replace(/(\d{6})(\d{4})/, "******$2");
  return maskedPhoneNumber;
};

export const emailWrapper = (email) => {
  const atIndex = email.indexOf("@");
  const modifiedEmail = "****" + email.slice(atIndex - 4);
  return modifiedEmail;
};

export const dateFormaterForData = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join("-");
};
export const dateFormaterForApi = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};

export const createdDatesAscendingSort = (data) => {
  const sortedData = [...data].sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });
  return sortedData;
};

export const createdDatesDescendingSort = (data) => {
  const sortedData = [...data].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });
  return sortedData;
};
export const updatedDatesAscendingSort = (data) => {
  const sortedData = [...data].sort((a, b) => {
    return new Date(a.updated_at) - new Date(b.updated_at);
  });
  return sortedData;
};

export const updatedDatesDescendingSort = (data) => {
  const sortedData = [...data].sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });
  return sortedData;
};

export const filterSelectedValue = (selectedValue) => {
  if (selectedValue === "today") {
    return "today";
  } else if (selectedValue === "last_7_days") {
    return "last_7_days";
  } else if (selectedValue === "last_month") {
    return "last_month";
  } else if (selectedValue === "last_three_month") {
    return "last_three_month";
  } else if (selectedValue === "last_six_month") {
    return "last_six_month";
  } else if (selectedValue === "one_year") {
    return "one_year";
  } else if (selectedValue === "current_month") {
    return "current_month";
  }
};

const currentDate = new Date();
export const today = format(addDays(currentDate, -1), "yyyy-MM-dd");
export const last7Days = format(addDays(currentDate, -7), "yyyy-MM-dd");
export const lastMonths = format(subMonths(currentDate, 1), "yyyy-MM-dd");
export const last3Months = format(subMonths(currentDate, 3), "yyyy-MM-dd");
export const last6Months = format(subMonths(currentDate, 6), "yyyy-MM-dd");
export const lastYear = format(subYears(currentDate, 1), "yyyy-MM-dd");
export const lastMonthFirstDay = format(startOfMonth(subMonths(currentDate, 1)), 'yyyy/MM/dd');

export const currentYear = currentDate.getFullYear();
const firstDayOfCurrentMonth = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
export const currentMonth = firstDayOfCurrentMonth;

const getCurrentYear = currentDate.getFullYear();
const getCurrentMonth = currentDate.getMonth() + 1;

export const endDateSelectionFilter = (selectedValue) => {
  if (selectedValue === "today") {
    return today;
  } else if (selectedValue === "last_7_days") {
    return last7Days;
  } else if (selectedValue === "last_month") {
    return lastMonths;
  } else if (selectedValue === "last_three_month") {
    return last3Months;
  } else if (selectedValue === "last_six_month") {
    return last6Months;
  } else if (selectedValue === "one_year") {
    return lastYear;
  } else if (selectedValue === "current_month") {
    return currentMonth;
  } else if (selectedValue === "last_month_date") {
    return lastMonthFirstDay;
  }
};

export const dateWiseBrokeragefilteredList = (
  overAllTradeAccountData,
  fromD,
  endD
) => {
  // ("check the data datewiseBrokerage", overAllTradeAccountData, fromD, endD);
  const filter = overAllTradeAccountData.filter((item) => {
    const itemTimestamp = dayjs(item.TransDate).valueOf();
    const startTimestamp = dayjs(fromD).valueOf();
    const endTimestamp = dayjs(endD).valueOf();
    return itemTimestamp >= startTimestamp && itemTimestamp <= endTimestamp;
  });
  // ("check the data filter", filter);
  return filter;
};

export const monthWiseBorkerageData = (filteredDataList) => {
  let data = [];
  const monthTotalBrokerage = {};
  let monthYear;
  // Calculate the total brokerage for each client code and month
  filteredDataList.forEach((entry) => {
    const brokerage = entry.TOTAL_BROKERAGE;
    const transDate = new Date(entry.TransDate);
    monthYear = `${transDate.getFullYear()}-${(transDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;

    // Update month-wise total brokerage
    if (monthTotalBrokerage[monthYear]) {
      monthTotalBrokerage[monthYear] += brokerage;
    } else {
      monthTotalBrokerage[monthYear] = brokerage;
    }
  });

  const sortedData = Object.entries(monthTotalBrokerage)
    .map(([monthYear, value]) => ({
      monthYear: new Date(monthYear),
      value,
    }))
    .sort((a, b) => a.monthYear - b.monthYear)
    .reduce((acc, { monthYear, value }) => {
      acc[monthYear.toISOString().slice(0, 7)] = value;
      return acc;
    }, {});

  if (sortedData !== 0) {
    data = Object.keys(sortedData).map((key) => {
      return {
        name: getMonths(key),
        Brokerage: sortedData[key].toFixed(2),
        Subscription: monthTotalBrokerage[key].toFixed(2),
      };
    });
  }
  return data;
};

export const monthlyTradingCategoryDataList = (filteredDataListBrokerage) => {
  let EquityData = [];
  let CommodityData = [];
  let TotalData = [];
  const monthlyBrokerageEquityData = {};
  const monthlyBrokerageCamodityData = {};
  const monthlyTotalBrokerage = {};

  let monthYear;
  // Calculate the total brokerage for each client code and month

  filteredDataListBrokerage.forEach((entry) => {
    const brokerage = entry.TOTAL_BROKERAGE;
    const tradingCategory = entry.exchcode;
    const transDate = new Date(entry.TransDate);
    monthYear = `${transDate.getFullYear()}-${(transDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;

    // Update month-wise total brokerage
    if (tradingCategory == "NSE") {
      if (monthlyBrokerageEquityData[monthYear]) {
        {
          monthlyBrokerageEquityData[monthYear] += brokerage;
        }
      } else {
        monthlyBrokerageEquityData[monthYear] = brokerage;
      }
    }
    if (tradingCategory == "NCDX" || tradingCategory == "MCX") {
      if (monthlyBrokerageCamodityData[monthYear]) {
        {
          monthlyBrokerageCamodityData[monthYear] += brokerage;
        }
      } else {
        monthlyBrokerageCamodityData[monthYear] = brokerage;
      }
    }

    if (
      tradingCategory == "NCDX" ||
      tradingCategory == "MCX" ||
      tradingCategory == "NSE"
    ) {
      if (monthlyTotalBrokerage[monthYear]) {
        {
          monthlyTotalBrokerage[monthYear] += brokerage;
        }
      } else {
        monthlyTotalBrokerage[monthYear] = brokerage;
      }
    }
  });

  // Manage Equity Data
  const sortedEquityData = Object.entries(monthlyBrokerageEquityData)
    .map(([monthYear, value]) => ({
      monthYear: new Date(monthYear),
      value,
    }))
    .sort((a, b) => a.monthYear - b.monthYear)
    .reduce((acc, { monthYear, value }) => {
      acc[monthYear.toISOString().slice(0, 7)] = value;
      return acc;
    }, {});

  if (sortedEquityData !== 0) {
    EquityData = Object.keys(sortedEquityData).map((key) => {
      return {
        name: getMonths(key),
        Equity: sortedEquityData[key].toFixed(2),
      };
    });
  }

  // For Comodity Data
  const sortedCommodityData = Object.entries(monthlyBrokerageCamodityData)
    .map(([monthYear, value]) => ({
      monthYear: new Date(monthYear),
      value,
    }))
    .sort((a, b) => a.monthYear - b.monthYear)
    .reduce((acc, { monthYear, value }) => {
      acc[monthYear.toISOString().slice(0, 7)] = value;
      return acc;
    }, {});

  if (sortedCommodityData !== 0) {
    CommodityData = Object.keys(sortedCommodityData).map((key) => {
      return {
        name: getMonths(key),
        Commodity: sortedCommodityData[key].toFixed(2),
      };
    });
  }

  // For Total Data

  const sortedTotalBrokerageData = Object.entries(monthlyTotalBrokerage)
    .map(([monthYear, value]) => ({
      monthYear: new Date(monthYear),
      value,
    }))
    .sort((a, b) => a.monthYear - b.monthYear)
    .reduce((acc, { monthYear, value }) => {
      acc[monthYear.toISOString().slice(0, 7)] = value;
      return acc;
    }, {});

  if (sortedTotalBrokerageData !== 0) {
    TotalData = Object.keys(sortedTotalBrokerageData).map((key) => {
      return {
        name: getMonths(key),
        Total: sortedTotalBrokerageData[key].toFixed(2),
      };
    });
  }

  return { EquityData, CommodityData, TotalData };
};

export const nameAscendingSort = (data) => {
  const sortedData = [...data].sort((a, b) =>
    a.full_name > b.full_name ? 1 : -1
  );
  return sortedData;
};

export const nameDescendingSort = (data) => {
  const sortedData = [...data].sort((a, b) =>
    a.full_name > b.full_name ? -1 : 1
  );
  return sortedData;
};

export const convertMonthNameToNumber = (monthName) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const index = months.indexOf(monthName);

  if (index !== -1) {
    // Add 1 to the index to get the month number, and pad with '0' if needed.
    return (index + 1).toString().padStart(2, "0");
  } else {
    // Handle invalid month name
    return null;
  }
};

export const getCompleteStatus = (step) => {
  if (step === 15 || step === 16) {
    return "Completed";
  } else {
    return "Pending";
  }
};

export function getStageNameForSteps(step) {
  if (step === 1 || step === 2) {
    return "Mobile";
  } else if (step === 3 || step === 4) {
    return "Email";
  } else if (step === 5) {
    return "PAN";
  } else if (step === 6) {
    return "KRA/Digilocker Completed";
  } else if (step === 7) {
    return "Personal Details";
  } else if (step === 8) {
    return "Occupational Details";
  } else if (step === 9) {
    return "Bank Details";
  } else if (step === 10) {
    return "Segments";
  } else if (step === 11 || step === 12) {
    return "Document upload";
  } else if (step === 13 || step === 14) {
    return "Selfee and Signature";
  } else if (step === 15 || step === 16) {
    return "E-sign";
  }
}

import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_MONTHWISE_CONV_UNCONV_LEADS, START_LOADING, STOP_LOADING } from "./actionType";

import {
  monthWiseApiResponseError,
  monthWiseApiResponseSuccess,
} from "./action";

import { postMonthwiseConvertedUnconvertedLeads } from "../../api/fakebackend_helper";

function* convUnconvLeads(data) {
  try {
    // yield put({ type: START_LOADING });
    const response = yield call(
      postMonthwiseConvertedUnconvertedLeads,
      data.payload
    );
    yield put(
      monthWiseApiResponseSuccess(GET_MONTHWISE_CONV_UNCONV_LEADS, response)
    );
    // yield put({ type: STOP_LOADING });
  } catch (error) {
    // yield put({ type: STOP_LOADING });
    yield put(
      monthWiseApiResponseError(GET_MONTHWISE_CONV_UNCONV_LEADS, error)
    );
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_MONTHWISE_CONV_UNCONV_LEADS, convUnconvLeads);
}

function* ConvUnconvLeadsSaga() {
  yield all([fork(watchGetCart)]);
}

export default ConvUnconvLeadsSaga;

import axios from "axios";
import { api } from "../config";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
// content type
// const client_token = localStorage.getItem("client_token");
// const token = JSON.parse(sessionStorage.getItem("authUser"))
//   ? JSON.parse(sessionStorage.getItem("authUser")).token
//   : null;
// if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
// //(client_token);
// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  // get2 = (url, params) => {
  //   const i =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjU1NjllNjVlOGU2N2VhYWIwZDVjMDlhNzA4ZjI0OTJjNzk5MGU3ZTIxNWE4ZTQxNGNjNTRlMGZjMjE4MDdiZjY3YjI2NGRiMTgzODdhODg4In0.eyJhdWQiOiI1IiwianRpIjoiNTU2OWU2NWU4ZTY3ZWFhYjBkNWMwOWE3MDhmMjQ5MmM3OTkwZTdlMjE1YThlNDE0Y2M1NGUwZmMyMTgwN2JmNjdiMjY0ZGIxODM4N2E4ODgiLCJpYXQiOjE2NzI4MjQyNjAsIm5iZiI6MTY3MjgyNDI2MCwiZXhwIjoxNzA0MzYwMjYwLCJzdWIiOiIxNSIsInNjb3BlcyI6W119.EEN7u0HuNEgYO8Aa4YIFPPVQV1-1SQ1-U6e5aO1KbzIwhhm4HF4FJHVxduTIJ0w5RswioCK7tn9ZaT1kcaYXuPV7Q_PTtNZB-hBpBdcXKfCaSx7i1lk665ht0GLnvDM7d5GV2xRWYNiPpNa_8Ek3tMHyVEKqd0vUT4_knxF25ii-NTrQgG0dgYRrUrN8-7SR2nNtzJ_JpRBm1GLDJ_GKl0_-PT2KqppvQk7rj_BDAQbM_5sy2Z8WcZlpA5JoMLXsFxTzVMNH63BcYtaFbIT-b9kZaHVOusY7JGaSQoDque5kDg_SD38OOyETS8EKvxs8sW0rfoDJWFVuvqeCMbI2YHaVo_AUdk06My4ftWu-dRGjGzzTcSmk_01R8lGXSq8C-6gnnGm8jEpUCLh_YTYJGMFc2RChQUo8mIMEgM_vfRUNjZD6zvE50LJWJwn9vXA7dccpe62Qys8lznIEGo4hk0zsvO0kPENH2cwrUvW5GO_ZqdJSZypvvrhxR1pEG064XJnqgPD8J5JJ81wDOS70XLtOTBevgwUWCJ_hbTU0KmaUUxvGz2tw8XR_CDQTSi97NIsR1rOboi4v96FuQFziOeYpabQtYljdI89PCRu5g0qt86xl3D1rBPhowHlDEyIpMYM2OyINHiG-PdF_RpXum7VaHz9Q2VfKjWMtveFV3XE";
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${i}`,
  //   };
  //   return axios.get(url, {
  //     headers: headers,
  //   });
  // };
  // /**
  //  * post given data to url
  //  */
  // create = (url, data) => {
  //   const i =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjU1NjllNjVlOGU2N2VhYWIwZDVjMDlhNzA4ZjI0OTJjNzk5MGU3ZTIxNWE4ZTQxNGNjNTRlMGZjMjE4MDdiZjY3YjI2NGRiMTgzODdhODg4In0.eyJhdWQiOiI1IiwianRpIjoiNTU2OWU2NWU4ZTY3ZWFhYjBkNWMwOWE3MDhmMjQ5MmM3OTkwZTdlMjE1YThlNDE0Y2M1NGUwZmMyMTgwN2JmNjdiMjY0ZGIxODM4N2E4ODgiLCJpYXQiOjE2NzI4MjQyNjAsIm5iZiI6MTY3MjgyNDI2MCwiZXhwIjoxNzA0MzYwMjYwLCJzdWIiOiIxNSIsInNjb3BlcyI6W119.EEN7u0HuNEgYO8Aa4YIFPPVQV1-1SQ1-U6e5aO1KbzIwhhm4HF4FJHVxduTIJ0w5RswioCK7tn9ZaT1kcaYXuPV7Q_PTtNZB-hBpBdcXKfCaSx7i1lk665ht0GLnvDM7d5GV2xRWYNiPpNa_8Ek3tMHyVEKqd0vUT4_knxF25ii-NTrQgG0dgYRrUrN8-7SR2nNtzJ_JpRBm1GLDJ_GKl0_-PT2KqppvQk7rj_BDAQbM_5sy2Z8WcZlpA5JoMLXsFxTzVMNH63BcYtaFbIT-b9kZaHVOusY7JGaSQoDque5kDg_SD38OOyETS8EKvxs8sW0rfoDJWFVuvqeCMbI2YHaVo_AUdk06My4ftWu-dRGjGzzTcSmk_01R8lGXSq8C-6gnnGm8jEpUCLh_YTYJGMFc2RChQUo8mIMEgM_vfRUNjZD6zvE50LJWJwn9vXA7dccpe62Qys8lznIEGo4hk0zsvO0kPENH2cwrUvW5GO_ZqdJSZypvvrhxR1pEG064XJnqgPD8J5JJ81wDOS70XLtOTBevgwUWCJ_hbTU0KmaUUxvGz2tw8XR_CDQTSi97NIsR1rOboi4v96FuQFziOeYpabQtYljdI89PCRu5g0qt86xl3D1rBPhowHlDEyIpMYM2OyINHiG-PdF_RpXum7VaHz9Q2VfKjWMtveFV3XE";
  //   const headers = {
  //     headers: { "content-type": "application/x-www-form-urlencoded" },
  //     Authorization: `Bearer ${i}`,
  //   };
  //   return axios.post(url, data, {
  //     headers: headers,
  //   });
  // };

  postMethodWithTokenType = (url, data) => {
    const i =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${i}`,
    };
    return axios.post(url, data, {
      headers: headers,
    });
  };

  postLoginTokken = (url, data) => {
    const i =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${i}`,
    };
    return axios.post(url, data, {
      headers: headers,
    });
  };

  postRawApi = (url, data) => {
    const i =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${i}`,
    };
    return axios.post(url, data, {
      headers: headers,
    });
  };

  // create22 = (url, data) => {
  //   const i =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjU1NjllNjVlOGU2N2VhYWIwZDVjMDlhNzA4ZjI0OTJjNzk5MGU3ZTIxNWE4ZTQxNGNjNTRlMGZjMjE4MDdiZjY3YjI2NGRiMTgzODdhODg4In0.eyJhdWQiOiI1IiwianRpIjoiNTU2OWU2NWU4ZTY3ZWFhYjBkNWMwOWE3MDhmMjQ5MmM3OTkwZTdlMjE1YThlNDE0Y2M1NGUwZmMyMTgwN2JmNjdiMjY0ZGIxODM4N2E4ODgiLCJpYXQiOjE2NzI4MjQyNjAsIm5iZiI6MTY3MjgyNDI2MCwiZXhwIjoxNzA0MzYwMjYwLCJzdWIiOiIxNSIsInNjb3BlcyI6W119.EEN7u0HuNEgYO8Aa4YIFPPVQV1-1SQ1-U6e5aO1KbzIwhhm4HF4FJHVxduTIJ0w5RswioCK7tn9ZaT1kcaYXuPV7Q_PTtNZB-hBpBdcXKfCaSx7i1lk665ht0GLnvDM7d5GV2xRWYNiPpNa_8Ek3tMHyVEKqd0vUT4_knxF25ii-NTrQgG0dgYRrUrN8-7SR2nNtzJ_JpRBm1GLDJ_GKl0_-PT2KqppvQk7rj_BDAQbM_5sy2Z8WcZlpA5JoMLXsFxTzVMNH63BcYtaFbIT-b9kZaHVOusY7JGaSQoDque5kDg_SD38OOyETS8EKvxs8sW0rfoDJWFVuvqeCMbI2YHaVo_AUdk06My4ftWu-dRGjGzzTcSmk_01R8lGXSq8C-6gnnGm8jEpUCLh_YTYJGMFc2RChQUo8mIMEgM_vfRUNjZD6zvE50LJWJwn9vXA7dccpe62Qys8lznIEGo4hk0zsvO0kPENH2cwrUvW5GO_ZqdJSZypvvrhxR1pEG064XJnqgPD8J5JJ81wDOS70XLtOTBevgwUWCJ_hbTU0KmaUUxvGz2tw8XR_CDQTSi97NIsR1rOboi4v96FuQFziOeYpabQtYljdI89PCRu5g0qt86xl3D1rBPhowHlDEyIpMYM2OyINHiG-PdF_RpXum7VaHz9Q2VfKjWMtveFV3XE";
  //   const headers = {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: `Bearer ${i}`,
  //   };

  //   const req = new FormData();
  //   for (var key in data) {
  //     req.append(key, data[key]);
  //   }

  //   return axios.post(url, req, {
  //     headers: headers,
  //   });
  // };

  // create3 = (url, data) => {
  //   //(data);
  //   const i =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjU1NjllNjVlOGU2N2VhYWIwZDVjMDlhNzA4ZjI0OTJjNzk5MGU3ZTIxNWE4ZTQxNGNjNTRlMGZjMjE4MDdiZjY3YjI2NGRiMTgzODdhODg4In0.eyJhdWQiOiI1IiwianRpIjoiNTU2OWU2NWU4ZTY3ZWFhYjBkNWMwOWE3MDhmMjQ5MmM3OTkwZTdlMjE1YThlNDE0Y2M1NGUwZmMyMTgwN2JmNjdiMjY0ZGIxODM4N2E4ODgiLCJpYXQiOjE2NzI4MjQyNjAsIm5iZiI6MTY3MjgyNDI2MCwiZXhwIjoxNzA0MzYwMjYwLCJzdWIiOiIxNSIsInNjb3BlcyI6W119.EEN7u0HuNEgYO8Aa4YIFPPVQV1-1SQ1-U6e5aO1KbzIwhhm4HF4FJHVxduTIJ0w5RswioCK7tn9ZaT1kcaYXuPV7Q_PTtNZB-hBpBdcXKfCaSx7i1lk665ht0GLnvDM7d5GV2xRWYNiPpNa_8Ek3tMHyVEKqd0vUT4_knxF25ii-NTrQgG0dgYRrUrN8-7SR2nNtzJ_JpRBm1GLDJ_GKl0_-PT2KqppvQk7rj_BDAQbM_5sy2Z8WcZlpA5JoMLXsFxTzVMNH63BcYtaFbIT-b9kZaHVOusY7JGaSQoDque5kDg_SD38OOyETS8EKvxs8sW0rfoDJWFVuvqeCMbI2YHaVo_AUdk06My4ftWu-dRGjGzzTcSmk_01R8lGXSq8C-6gnnGm8jEpUCLh_YTYJGMFc2RChQUo8mIMEgM_vfRUNjZD6zvE50LJWJwn9vXA7dccpe62Qys8lznIEGo4hk0zsvO0kPENH2cwrUvW5GO_ZqdJSZypvvrhxR1pEG064XJnqgPD8J5JJ81wDOS70XLtOTBevgwUWCJ_hbTU0KmaUUxvGz2tw8XR_CDQTSi97NIsR1rOboi4v96FuQFziOeYpabQtYljdI89PCRu5g0qt86xl3D1rBPhowHlDEyIpMYM2OyINHiG-PdF_RpXum7VaHz9Q2VfKjWMtveFV3XE";
  //   const headers = {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: `Bearer ${i}`,
  //   };
  //   const req = new FormData();
  //   for (var key in data) {
  //     req.append(key, data[key]);
  //   }

  //   ////([...req]);
  //   return axios.post(url, req, {
  //     headers: headers,
  //   });
  // };
  // create4 = (url, data) => {
  //   const i =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjFhMGI1OTI2ZGM4ZDFlN2M1NmE0NTcyZmQwNGI3MDQ0YThhZDUyNWVlMTVlODQ0ZmFlMWFmN2VjNTlmZTQyODY5NWFmYmUwZGFhMjc0MzE3In0.eyJhdWQiOiI1IiwianRpIjoiMWEwYjU5MjZkYzhkMWU3YzU2YTQ1NzJmZDA0YjcwNDRhOGFkNTI1ZWUxNWU4NDRmYWUxYWY3ZWM1OWZlNDI4Njk1YWZiZTBkYWEyNzQzMTciLCJpYXQiOjE2NjU5ODgwOTcsIm5iZiI6MTY2NTk4ODA5NywiZXhwIjoxNjk3NTI0MDk3LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.iLJnrTlZqVpDV-ipc7RH5u3bdK6yB9cOEBcityOMBK8n-nH__c5ITO4MHHc0xaDcxTvIxC8L4GJ7FzJqrol2fdMkjIvS3AhuhIlbEeSU6rDGOwqlfRXZxM-_eszGMF4Py3obK3QjUzBWNM3NAHS4xIVRhx3l7RKZpP2QU3s4DqHNa513N2ieUShDBh5TnPtS1lIVVINLVrePNGVNAdDu4TPGq73eq3t7nZjqcsYGsTGp_d7u67pcy09KtEpU4IHFuLUB9iuyFkEbaSUuXN65FsyDv5_uRQ4-VtrgUph1Sao8kTJ0bkQIBjaTqyKXLgxJkAt9L2h7CfanZ_5rlsr81D1M4UqQ6WW7b1cLPd9eL07JW_mTQnq3_UiePs4GiAKnGfBebLjsMqO0IsjN3g06i8PcOkN1jIxJnvjtz6I93sAJB1PF2bjaVOEyAouPtkw6Z4OOQbjutnYo3k47OUWBJ_iBj_j1ltc3OVSOnzQS0vVZelIB05K-hF6oPlP4dKzFFb3S90KrpHJYg8oiVu-z9-jg_EN5R3TYEp0gf45Z75AxUimgcf7Z5SEg-gHap24vpspsdqUrjsDfhwukdc4y381n1dDdVxeHe-22aGkK50Mwdx2pztYw8i68GgkP9e7sXy94f6dKpMf7A2Kghfm21O5yniXFd0VbO8F8mx26dXY";
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${i}`,
  //   };
  //   return axios.post(url, data, {
  //     headers: headers,
  //   });
  // };

  // create5 = (url, data) => {
  //   //(data);
  //   // const i =
  //   //   "QUlLR1g2RVpFVDMyNDEzSzY4NDhTTUlGMU40VU1TQlE6SE1ROFJFUEI4TkU2SzJJTFRGSlFPOVQ1WFk4QUJDR0M=";
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Basic QUlLR1g2RVpFVDMyNDEzSzY4NDhTTUlGMU40VU1TQlE6SE1ROFJFUEI4TkU2SzJJTFRGSlFPOVQ1WFk4QUJDR0M=`,
  //   };

  //   return axios.post(url, data, {
  //     headers: headers,
  //   });
  // };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };

import {
  API_LOGIN_SUCCESS, API_LOGIN_FAIL, GET_LOGIN, REMOVE_LOGIN_DATA,
  START_LOADING,
  STOP_LOADING,
  API_OTP_SUCCESS,
  GET_OTP,
  REMOVE_OTP_DATA,
  API_OTP_FAIL,
} from "./actionType";

const data = localStorage.getItem("userData")
const INIT_STATE = {
  logingData: "",
  loginError: "",
  isLoading: false,
  otpData: '',
  otpError: ''
};

const OtpDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case API_OTP_SUCCESS:
      switch (action.payload.actionType) {
        case GET_OTP:
          return {
            ...state,
            otpData: action.payload.data ? action.payload.data : [],

          };

        default:
          return { ...state };
      }
    case API_OTP_FAIL:
      switch (action.payload.actionType) {
        case GET_OTP:
          return {
            ...state,
            otpError: action.payload.error,
          };
        default:
          return { ...state };
      }
    case REMOVE_OTP_DATA:
      return {
        ...state,
        logingData: [],
      };
    case GET_OTP: {
      return {
        ...state,
      };
    }
    case STOP_LOADING:
      return { ...state, isLoading: false };

    default:
      return { ...state };
  }
};

export default OtpDetails;

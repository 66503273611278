import {
  API_STAGEWISE_UNCONVERTED_LEADS_SUCCESS,
  API_STAGEWISE_UNCONVERTED_LEADS_FAIL,
  GET_STAGEWISE_UNCONVERTED_LEADS,
  STAGEWISE_STEPS_DATA,
} from "./actionType";

const INIT_STATE = {
  stagewiseUnconvLeads: [],
  stagewiseStepsData: [],
};

const stagewiseUnconvLeads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_STAGEWISE_UNCONVERTED_LEADS_SUCCESS:
      switch (action.payload.actionType) {
        case GET_STAGEWISE_UNCONVERTED_LEADS:
          return {
            ...state,
            stagewiseUnconvLeads:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        case STAGEWISE_STEPS_DATA:
          return {
            stagewiseStepsData: action,
          };

        default:
          return { ...state };
      }
    case API_STAGEWISE_UNCONVERTED_LEADS_FAIL:
      switch (action.payload.actionType) {
        case GET_STAGEWISE_UNCONVERTED_LEADS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_STAGEWISE_UNCONVERTED_LEADS: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default stagewiseUnconvLeads;

import { API_INCOME_GET_CLIENT_LIST_FAIL, API_INCOME_GET_CLIENT_LIST_SUCCESS, GET_INCOME_GET_CLIENT_LIST } from "./actionType";

export const ClientListApiResponseSuccess = (actionType, data) => ({
  type: API_INCOME_GET_CLIENT_LIST_SUCCESS,
  payload: { actionType, data },
});

export const ClientListApiResponseError = (actionType, error) => ({
  type: API_INCOME_GET_CLIENT_LIST_FAIL,
  payload: { actionType, error },
});

export const getSUbScriptionWiseIncomeApi = (data) => ({
  type: GET_INCOME_GET_CLIENT_LIST,
  payload: data,
});
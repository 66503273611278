import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_RECOMMENDED_ALGOS } from "./actionType";

import { algosApiResponseError, algosApiResponseSuccess } from "./action";

import { getRecommendedAlgosApi } from "../../api/fakebackend_helper";

function* RecommendedAlgos(data) {
  try {
    const response = yield call(getRecommendedAlgosApi, data.data);
    yield put(algosApiResponseSuccess(GET_RECOMMENDED_ALGOS, response));
  } catch (error) {
    yield put(algosApiResponseError(GET_RECOMMENDED_ALGOS, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_RECOMMENDED_ALGOS, RecommendedAlgos);
}

function* RecommendedAlgosSaga() {
  yield all([fork(watchGetCart)]);
}

export default RecommendedAlgosSaga;

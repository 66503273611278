import { call, put, takeEvery, all, fork, retry } from "redux-saga/effects";

import { GET_LOGIN, START_LOADING, STOP_LOADING } from "./actionType";

import { loginApiResponseError, loginApiResponseSuccess } from "./action";

import { postLoginApi } from "../../api/fakebackend_helper";

function* loginDeatils(data) {
  try {
    yield put({ type: START_LOADING });
    const response = yield call(postLoginApi, data.payload);
    // response?.success && localStorage.setItem("userData", JSON.stringify(response))
    yield put(loginApiResponseSuccess(GET_LOGIN, response));
    yield put({ type: STOP_LOADING });
  } catch (error) {
    yield put({ type: STOP_LOADING });
    yield put(loginApiResponseError(GET_LOGIN, error));
  }
}

export function* watchGetCart() {
  // yield put({ type: START_LOADING });
  yield takeEvery(GET_LOGIN, loginDeatils);
}

function* LoginDetailsSaga() {
  yield all([fork(watchGetCart)]);
}

export default LoginDetailsSaga;

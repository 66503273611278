import { combineReducers } from "redux";
//VERIFY_OTP
import totalEarning from "./TotalEarning/reducer";
import monthwiseConvUnconvLeads from "./MonthwiseConUnconLeads/reducer";
import isLoading from "./RecommendedAlgo/reducer";
import leadSummaryData from "./LeadSummaryConvUnconv/reducer";
import stagewiseUnconvLeads from "./StagewiseUnconvertedLeads/reducer";
import refferalData from "./ReferralLink/reducer";
import logingData from "./LoginApi/reducer";
import userIdData from "./UserCode/reducer";
import ClientWiseincomeList from "./InComePage/getBrokrageListApi/reducer"
import SubScriptionWiseList from "./InComePage/getClientListApi/reducer"
import getTotalAccountOpned from "./allLeadsPage/getTotalAccountOpned/reducer"
import MonthlyOpendTrade from "./MonthlyOpnedTrade/reducer";
import getUnConvertedLIst from "./allLeadsPage/getAllUnConverted/reducer";
import OtpDetails from "./otpPage/reducer";
// import isLoading from "./MonthwiseConUnconLeads/reducer"

const rootReducer = combineReducers({
  // isLoading,
  totalEarning,
  monthwiseConvUnconvLeads,
  leadSummaryData,
  stagewiseUnconvLeads,
  isLoading,
  refferalData,
  logingData,
  userIdData,
  MonthlyOpendTrade,
  getUnConvertedLIst,

  ClientWiseincomeList,
  SubScriptionWiseList,
  getTotalAccountOpned,
  OtpDetails
});

export default rootReducer;

import { API_INCOME_GET_CLIENT_LIST_FAIL, API_INCOME_GET_CLIENT_LIST_SUCCESS, API_MONTHLY_OPNED_TRADE_FAIL, API_MONTHLY_OPNED_TRADE_SUCCESS, GET_INCOME_GET_CLIENT_LIST, GET_MONTHLY_OPNED_TRADE_LIST } from "./actionType";

  const INIT_STATE = {
    MonthlyOpendTrade: [],
  };
  
  const MonthlyOpnedTrade = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_MONTHLY_OPNED_TRADE_SUCCESS:
        switch (action.payload.actionType) {
          case GET_MONTHLY_OPNED_TRADE_LIST:
            return {
              ...state,
              MonthlyOpendTrade:
                action.payload.data.data !== null ? action.payload.data.data : [],
            };
          default:
            return { ...state };
        }
      case API_MONTHLY_OPNED_TRADE_FAIL:
        switch (action.payload.actionType) {
          case GET_MONTHLY_OPNED_TRADE_LIST:
            return {
              ...state,
              error: action.payload.error,
            };
          default:
            return { ...state };
        }
      case GET_MONTHLY_OPNED_TRADE_LIST: {
        return {
          ...state,
        };
      }
      default:
        return { ...state };
    }
  };
  
  export default MonthlyOpnedTrade;
  
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

const IncomePieChart = ({
    legendPositonH,
    legendPositonV,
    brokerageClients,
    brokerageAmount,
    subscriptionClient,
    subscriptionAmount,
    toggleValue,
}) => {
    const [dataValues, setDataValues] = useState([]);
    const [labelText, setLabelText] = useState("");
    // (">>>>>>>>>", brokerageAmount);
    useEffect(() => {
        const bAmount = brokerageAmount ? parseFloat(brokerageAmount.replace(/,/g, '')) : null;
        const sAmount = subscriptionAmount ? parseFloat(subscriptionAmount.replace(/,/g, '')) : null;

        if (toggleValue === "income") {
            setDataValues([
                bAmount || 0,
                sAmount || 0,
            ]);
            setLabelText(
                `Brokerage: ${brokerageAmount || 0} | Subscription: ${subscriptionAmount || 0
                }`
            );
        } else if (toggleValue === "client") {
            setDataValues([brokerageClients || 0, subscriptionClient || 0]);
            setLabelText(
                `Brokerage Client: ${brokerageClients || 0} | Subscription Client: ${subscriptionClient || 0
                }`
            );
        }
    }, [toggleValue, brokerageClients, brokerageAmount, subscriptionClient, subscriptionAmount]);
    const pieOptions = {
        legend: {
            display: true,
            position: "bottom",
        },
        elements: {
            arc: {
                borderWidth: 1,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const dataIndex = tooltipItem.index;
                    const label = data.labels[dataIndex];
                    const value = data.datasets[0].data[dataIndex];

                    if (toggleValue === "income") {
                        return `${label}: ${value}`;
                    } else if (toggleValue === "client") {
                        const clientLabel = data.labels[dataIndex] === "Brokerage" ? "Brokerage Client" : "Subscription Client";
                        return `${clientLabel}: ${value}`;
                    }
                },
            },
        },
    };


    const data = {
        maintainAspectRatio: true,
        responsive: true,
        labels: ["Business Points", "Subscription Points "],
        datasets: [
            {
                label: "My First Dataset",
                data: dataValues,
                backgroundColor: ["#0A3294", "#53DEFC"],

                hoverOffset: 4,
            },
        ],
    };

    return (
        <Doughnut data={data} width={"25%"} height={"20%"} options={pieOptions} />
    );
};

export default IncomePieChart;

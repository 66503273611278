import {
  API_MONTHWISE_CONV_UNCONV_LEADS_SUCCESS,
  API_MONTHWISE_CONV_UNCONV_LEADS_FAIL,
  GET_MONTHWISE_CONV_UNCONV_LEADS,
  START_LOADING,
  STOP_LOADING,
  RESET_MONTHWISE_LEADS,
} from "./actionType";

const INIT_STATE = {
  monthwiseConvUnconvLeads: [],
  isLoading: false,
};

const monthWiseConUnconLeads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_MONTHWISE_CONV_UNCONV_LEADS_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MONTHWISE_CONV_UNCONV_LEADS:
          return {
            ...state,
            monthwiseConvUnconvLeads:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_MONTHWISE_CONV_UNCONV_LEADS_FAIL:
      switch (action.payload.actionType) {
        case GET_MONTHWISE_CONV_UNCONV_LEADS:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_MONTHWISE_CONV_UNCONV_LEADS: {
      return {
        ...state,
      };
    }
    case RESET_MONTHWISE_LEADS:
      return {
        ...state,
        monthwiseConvUnconvLeads: [],
      };
    default:
      return { ...state };
  }
};

export default monthWiseConUnconLeads;

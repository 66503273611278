import {
  API_TOTAL_EARNING_SUCCESS,
  API_TOTAL_EARNING_FAIL,
  GET_TOTAL_EARNING,
  EMPTY_TOTAL_EARNING,
} from "./actionType";

const INIT_STATE = {
  totalEarning: [],
};

const totalEarningDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_TOTAL_EARNING_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TOTAL_EARNING:
          return {
            ...state,
            totalEarning:
              action.payload.data.data !== null ? action.payload.data.data : [],
          };
        default:
          return { ...state };
      }
    case API_TOTAL_EARNING_FAIL:
      switch (action.payload.actionType) {
        case GET_TOTAL_EARNING:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_TOTAL_EARNING: {
      return {
        ...state,
      };
    }
    case EMPTY_TOTAL_EARNING: // Handle the EMPTY_TOTAL_EARNING action
      return {
        ...state,
        totalEarning: [] // Set totalEarning to an empty array
      };
    default:
      return { ...state };
  }
};

export default totalEarningDetails;

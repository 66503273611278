import {
  API_REFERRAL_LINK_SUCCESS,
  API_REFERRAL_LINK_FAIL,
  GET_REFERRAL_LINK,
} from "./actionType";

const INIT_STATE = {
  refferalData: {},
};

const accountTradeEarn = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_REFERRAL_LINK_SUCCESS:
      switch (action.payload.actionType) {
        case GET_REFERRAL_LINK:
          return {
            ...state,
            refferalData:
              action.payload.data !== null ? action.payload.data : {},
          };
        default:
          return { ...state };
      }
    case API_REFERRAL_LINK_FAIL:
      switch (action.payload.actionType) {
        case GET_REFERRAL_LINK:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return { ...state };
      }
    case GET_REFERRAL_LINK: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
};

export default accountTradeEarn;

import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED, START_LOADING, STOP_LOADING } from "./actionType";



import { allLeadsApiResponseError, allLeadsApiResponseSuccess, allLeadsUnconvertedListApiResponseError, allLeadsUnconvertedListApiResponseSuccess } from "./action";
import { postAllLeadsAccountOpned, postAllLeadsUnconvertedList } from "../../../api/fakebackend_helper";

function* AllLeadsUnConverted(data) {
    try {
        const response = yield call(postAllLeadsUnconvertedList, data.payload);
        yield put(allLeadsUnconvertedListApiResponseSuccess(GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED, response));
    } catch (error) {
        yield put(allLeadsUnconvertedListApiResponseError(GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED, error));
    }
}

export function* watchGetCart() {
    yield takeEvery(GET_ALLLEADS_GET_UNCONVERTED_LIST_OPNED, AllLeadsUnConverted);
}

function* AllLeadsUnCOnvertedSaga() {
    yield all([fork(watchGetCart)]);
}

export default AllLeadsUnCOnvertedSaga;

import {
  GET_LEAD_SUMMARY_CONV_UNCONV,
  API_LEAD_SUMMARY_CONV_UNCONV_SUCCESS,
  API_LEAD_SUMMARY_CONV_UNCONV_FAIL,
} from "./actionType";

export const leadSummaryApiResponseSuccess = (actionType, data) => ({
  type: API_LEAD_SUMMARY_CONV_UNCONV_SUCCESS,
  payload: { actionType, data },
});

export const leadSummaryApiResponseError = (actionType, error) => ({
  type: API_LEAD_SUMMARY_CONV_UNCONV_FAIL,
  payload: { actionType, error },
});

export const getLeadSummaryConvUnconv = (data) => ({
  type: GET_LEAD_SUMMARY_CONV_UNCONV,
  payload: data,
});

import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

export const getTotalAccountApi = (data) =>
  api.get(url.GET_TOTAL_ACCOUNT, data);
// ("get parameteres: ", data);
export const getTotalEarningApi = (data) =>
  //   ("get parameteres: ", data);
  api.get(
    url.GET_TOATL_EARNING +
    `fn=${data.fnValue}&incode=${data.inCodeValue}&FromDate=${data.fromDateValue}&ToDate=${data.toDateValue}`
    //   "fn=getMarginTranMyetrade&incode=ALL&FromDate=2023-09-01&ToDate=2023-09-01"
  );
export const getRecommendedAlgosApi = (data) =>
  api.get(
    url.GET_RECOMMENDED_ALGOS +
    "api/all-feature-list?client_code=MM0A1458%20%20&client_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkExNDU4IiwiTWlkZGxlV2FyZV9NYXBwaW5nIjoiMTEiLCJNaWRkbGVXYXJlX0pzb25EYXRhIjoie1wiUHVibGljS2V5NHBlbVwiOlwiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5U1VRaU9pSkJNVFExT0NJc0ltMWxiV0psY2tsRUlqb2lRazlPUVU1YVFTSXNJbk52ZFhKalpTSTZJbGRGUWlJc0ltbGhkQ0k2TVRZNU16UTFPVFUwTVN3aVpYaHdJam94Tmprek5UUTFPVFF4ZlEuMVlzaVNxQmZ5ZVNjY1c5b3VUSUl3X2luY0pGWnlNUW16ODVXWUFEYXV4OFwiLFwiTG9naW5JRFwiOlwiQTE0NThcIixcIkxhc3RMb2dpblRpbWVcIjpcIjgvMzEvMjAyMyAxMDo1NTo0MSBBTVwifSIsIkRldmljZUlkIjoiIiwiRGV2aWNlVHlwZSI6IiIsIkxvZ2luSWQiOiJBMTQ1OCIsIm5iZiI6MTY5MzQ2MDc0MywiZXhwIjoxNjkzNTQ3MTQzLCJpYXQiOjE2OTM0NjA3NDN9.YbshzdS2qfQj3jKTn6MrPuJ7U9yeM93uQANc4jNyZgE"
  );

export const getTotalTradeAccountApi = (data) =>
  api.get(url.GET_TOTAL_TRADE_ACCOUNT, data);

export const postMonthwiseConvertedUnconvertedLeads = (data) =>
  api.postMethodWithTokenType(
    url.POST_MONTH_WISE_CONVERTED_UNCONVERTED_LEADS,
    data
  );
/// >>>>>>>>>>>>>>>>>>>>INcome Page are used>>>>>>>>>>>>>>>>>>>.
export const postBrokrageListIncomePage = (data) =>
  api.postMethodWithTokenType(url.POST_GET_BROKERAGE_CLIENT_LIST, data);

export const postSubScriptionListIncomePage = (data) =>
  api.postMethodWithTokenType(url.POST_GET_SUBSCRIPTION_LIST_INCOME, data);
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>All Leads page>>>>>>>>>>>
export const postAllLeadsAccountOpned = (data) =>
  api.postMethodWithTokenType(url.POST_GET_TOTAL_ACCOUNT_OPENED, data);
  export const postAllLeadsUnconvertedList = (data) =>
  api.postMethodWithTokenType(url.POST_ACC_OPENING_PROCESS, data);
  //>>>>>>>>>>>>>>>>>>........................
export const postMonthlyOpnedTradeApi = (data) =>
  api.postMethodWithTokenType(url.POST_GET_TRADED_OPNE_ACCOUNT, data);


export const postGetToalEaring = (data) => api.postRawApi(
  url.POST_TOTAL_EARNING,
  data
)

export const postLoginApi = (data) => api.postLoginTokken(url.POST_LOGIN, data);
// export const postOtpApi =(data) =>api.postLoginTokken(url.OTP_CHECK,data)
export const postOtpApi = (data) => api.get(url.OTP_CHECK + `?username=${data.userId}&otp=${data.otp}`);

export const postReferralLinkApi = (data) =>
  api.postLoginTokken(
    url.POST_REFERRAL_LINK + "referal-link-partner",
    data
  );
export const postGetSubScriptionList = (data) =>
  api.postMethodWithTokenType(
    url.POST_GET_SUBSCRIPTION_LIST + "/getSubscriptionList",
    data
  );
export const postLeadsDetails = (data) =>
  api.postMethodWithTokenType(url.POST_LEADS_DETAIL, data);

export const accountTradeEarnApi = (data) =>
  api.get(url.GET_ACCOUNT_TRADE_EARN, data);

export const totalEarnMonthWiseApi = (data) =>
  api.get(url.GET_TOTAL_EARN_MONTH_WISE, data);

export const postLeadsSummaryConvUnconv = (data) =>
  api.postMethodWithTokenType(url.POST_LEAD_SUMMARY_CONV_UNCONVERTED, data);

export const postAllUnconvertedLeadsApi = (data) => {
  api.postMethodWithTokenType(url.POST_ACC_OPENING_PROCESS, data);
}

// export const postGetTradeOpnedAccount = (data) => {
//   api.postMethodWithTokenType(url.POST_GET_TRADED_OPNE_ACCOUNT, data);
// }

export const postStagewiseUnconvertedLeads = (data) =>
  api.postMethodWithTokenType(url.POST_STAGEWISE_UNCONVERTED_LEADS, data);

// https://client-dasboardapis.bigul.app/api/all-feature-list?client_code=MM0A1458%20%20&client_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkExNDU4IiwiTWlkZGxlV2FyZV9NYXBwaW5nIjoiMTEiLCJNaWRkbGVXYXJlX0pzb25EYXRhIjoie1wiUHVibGljS2V5NHBlbVwiOlwiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5U1VRaU9pSkJNVFExT0NJc0ltMWxiV0psY2tsRUlqb2lRazlPUVU1YVFTSXNJbk52ZFhKalpTSTZJbGRGUWlJc0ltbGhkQ0k2TVRZNU16UTFPVFUwTVN3aVpYaHdJam94Tmprek5UUTFPVFF4ZlEuMVlzaVNxQmZ5ZVNjY1c5b3VUSUl3X2luY0pGWnlNUW16ODVXWUFEYXV4OFwiLFwiTG9naW5JRFwiOlwiQTE0NThcIixcIkxhc3RMb2dpblRpbWVcIjpcIjgvMzEvMjAyMyAxMDo1NTo0MSBBTVwifSIsIkRldmljZUlkIjoiIiwiRGV2aWNlVHlwZSI6IiIsIkxvZ2luSWQiOiJBMTQ1OCIsIm5iZiI6MTY5MzQ2MDc0MywiZXhwIjoxNjkzNTQ3MTQzLCJpYXQiOjE2OTM0NjA3NDN9.YbshzdS2qfQj3jKTn6MrPuJ7U9yeM93uQANc4jNyZgE

// http://14.141.71.10:9494/bigulpay/paymentapi_uat.php?fn=getMarginTranMyetrade&incode=ALL&FromDate=2023-09-01&ToDate=2023-09-01

// export const getNotificationsListApi = (data) => api.get(url.GET_NOTIFICATIONS_LIST + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getELearningApiListApi = (data) => api.get(url.GET_ELEARNINGVIDEOS_LIST + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getELearningACategoryListApi = (data) => api.get(url.GET_ELEARNINGVIDEOS_CATEGORY_LIST + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getELearningByCategoryApi = (data) => api.get(url.GET_ELEARNINGVIDEOS_BY_CATEGORY_LIST + `category=${data.e_id}&client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getProfileDetailsApi = (data) => api.get(url.GET_PROFILE_DETAILS + data.client_code);

// export const getHighlightsDetailsApi = (data) => api.get(url.GET_HIGHLIGHTS_DETAILS + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getSuggestedAlgoApi = (data) => api.get(url.GET_SUGGESTED_ALGO + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getAddOnApi = (data) => api.get(url.GET_Add_On + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getFeatureAndPlanApi = (data) => api.get(url.GET_FEATURE_AND_PLAN + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getMyPlanApi = (data) => api.get(url.GET_MY_PLAN + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getMyPlanDurationApi = (data) => api.get(url.GET_MY_PLAN_DURATION + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getMyFeaturePlanApi = (data) => api.get(url.GET_MY_FEATURE_PLAN + `${data.client_code}?client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getMyFeatureSuggestedPlanApi = (data) => api.get(url.GET_MY_FEATURE_SUGGESTED_PLAN + `client_code=${data.client_code}&client_token=${data.client_token}`);

// export const getEncryptKeyApi = (data) => api.get(url.GET_ENCRYPT_KEY + `strUserId=${data.client_id}`);

// export const getTradeBookCashApi = (data) => api.get(`${url.GET_TRADE_BOOK_CASH}apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&dbyr=22-23`);

// export const getTradeBookDerivativeApi = (data) => api.get(`${url.GET_TRADE_BOOK_DERIVATIVE}apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&dbyr=22-23`);

// export const getLedgerApi = (data) => api.get(`${url.GET_LEDGER}apikey=${encodeURIComponent(data.apiKey)}&dbyr=${data.fYear}&firm=SEC&code=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=N&filter=ALL&Seg=ALL&ascdsc=ASC`);

// export const getPAndLCashApi = (data) => api.get(`${url.GET_P_AND_L_CASH}apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&seg=C&inclchg=${data.charges}`);

// //export const getPAndLDerivativeApi = (data) => api.get(`${url.GET_P_AND_L_DERIVATIVE}apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&seg=${data.segment}&inclchg=${data.charges}`);
// export const getPAndLDerivativeApi = (data) => api.get(`https://clientlevel.bonanzaonline.com:3031/Bonanza_API/CLSqlInterface.svc/glder?apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&seg=${data.segment}&inclchg=${data.charges}`);

// export const getPAndLDerivative2Api = (data) => api.get(`${url.P_AND_L_DERIVATIVE_2}apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&seg=${data.segment}&inclchg=${data.charges}`);

// export const getHoldingsApi = (data) => api.get(`${url.GET_HOLDING}apikey=${encodeURIComponent(data.apiKey)}&dbyr=22-23&dcode=${data.code}&ordby=scripname&ascdsc=ASC&ratetype=M&Seg=C&tot=N&dp=N&Hdate=-`);

// export const getTaxReportApi = (data) => api.get(`${url.GET_TAX_REPORT}apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}`);

// export const getMarginOverviewApi = (data) => api.get(`${url.GET_AVAILABLE_MARGIN}apikey=${encodeURIComponent(data.apiKey)}&firm=SEC&dcode=${data.code}`);

// export const getBankDetailsApi = (data) => api.get(`${url.GET_BANK_DETAIlS}apikey=${encodeURIComponent(data.apiKey)}&firm=SEC&dcode=${data.code}`);

// export const withdrawApi = (data) => api.get(`${url.GET_WITHDRAW}apikey=${encodeURIComponent(data.apiKey)}&firm=SEC&dcode=${data.code}&trftype=NEFT&amt=${data.amount}`);

// export const getInvestmentSummaryApi = (data) => api.get(`${url.GET_INVESTED_SUMMARY}apikey=${encodeURIComponent(data.apiKey)}&dbyr=22-23&dcode=${data.code}&ratetype=N&seg=C`);

// export const getMyInvestedAlgosGraphApi = (data) => api.create(url.GET_MY_INVESTED_ALGO_GRAPH, data);

// export const geCurrentOverviewPMSApi = (data) => api.create(url.GET_CURRENT_OVERVIEW_PMS, data);

// export const geCurrentOverviewMFApi = (data) => api.create(url.GET_CURRENT_OVERVIEW_MF, data);

// export const getCollateralApi = (data) => api.get(url.COLLATERAL_GET + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}`);

// export const getCurrentOverviewSAApi = (data) => api.create(url.GET_CURRENT_OVERVIEW_SA, data);

// export const getCartApi = (data) => api.create(url.GET_GET_CART_LIST, data);

// export const addItemToCartApi = (data) => api.create(url.ADD_ITEM_TO_CART, data);

// export const removeItemToCartApi = (data) => api.create(url.REMOVE_ITEM_TO_CART, data);

// export const PaymentDetailsApi = (data) => api.create(url.GET_PAYMENT_DETAILS, data);

// export const getSummaryTableApi = (data) => api.create(url.GET_SUMMARY, data);

// export const getPlanHistoryApi = (data) => api.create(url.GET_PLAN_HISTORY + `start_date=${data.startDate}&to_date=${data.endDate}&client_code=${data.client_code}`, data);

// export const UpdateItemToCartApi = (data) => {
//   ////(data)
//   return api.create(url.UPDATE_ITEM_TO_CART + data.data2 , data.data)};

// export const submitOtpApi = (data) => api.create(url.SUBMIT_OTP, data);

// export const resendOtpApi = (data) => api.create(url.RESEND_OTP, data);

// export const sendApi = (data) => api.create(url.SEND_OTP, data);

// export const sendApi2 = (data) => api.create(url.SEND_BANK, data);

// export const callBackApi = (data) => api.create(url.CALL_BACK, data);

// export const downloadMarginOverviewApi = (data) => api.get(url.DOWNLOAD_MARGIN + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=22-23&firm=SEC&code=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=Y&filter=ALL&Seg=ALL&ascdsc=ASC`);

// export const latestUpdateOnApi = (data) => api.create(url.LATEST_UPDATE_ON, data);

// export const ledgerApi = (data) => api.get(url.LEDGER_API + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&firm=SEC&code=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=N&filter=ALL&Seg=ALL&ascdsc=ASC`);

// export const collateralApi = (data) => api.get(url.COLLATERAL_API + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}`);

// export const marginApi = (data) => api.get(url.MARGIN_API + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&firm=SEC&code=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=Y&filter=ALL&Seg=ALL&ascdsc=ASC`);

// export const selectedPlanApi = (data) => api.get(url.ELECTED_PLAN + `${data.planID}?client_code=${data.code}&client_token=${data.token}`);

// export const selectedFeatureApi = (data) => api.get(url.SELECTED_FEATURE + `${data.planID}?client_code=${data.code}&client_token=${data.token}`);

// export const referAndEarnApi = (data) => api.create(url.REFER_AND_EARN, data);

// export const pmsDownloadApi = (data) => api.create(url.PMS_DOWNLOAD, data);

// export const taxReportListApi = (data) => api.get(url.TAX_REPORT_LIST_GET + `client_code=${data.code}&client_token=${data.token}`);

// export const taxDetailsApi = (data) => api.get(url.TAX_DETAILS_GET + `${data.id}?client_code=${data.code}&client_token=${data.token}`);

// export const taxReportApi = (data) => api.get(url.TAX_REPORT_GET + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}&sdate=01-04-${data.start_year}&edate=31-03-${data.end_year}`);

// export const contractNoteMonthlyApi = (data) => api.get(url.CONTRACT_NOTE_MONTHLY + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=22-23&dcode=${data.code}&mthinname=${data.year}`);

// export const contractNoteDownloadApi = (data) => api.get(url.CONTRACT_NOTE_DOWNLOAD + `apikey=${encodeURIComponent(data.apiKey)}&Filename=${data.fileName}`);

// export const getProfileBusinessDetailsApi = (data) => api.create2(url.PROFILE_BUSINESS_INFO, data);

// export const getProfileNomineeApi1 = (data) => api.get(url.PROFILE_NOMINEE_INFO1 + `${data.pan}`);

// export const getProfileNomineeApi2 = (data) => api.get(url.PROFILE_NOMINEE_INFO2 + `${data.pan}`);

// export const pledgeUpdate = (data) => api.get(url.PLEDGE_UPDATE + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}&scode=${data.scode}&isin=${data.isin}&dpcode=${data.dpcode}&dpid=${data.dpid}&pdpcode=${data.pdpcode}&pdpid=${data.pdpid}&tdate=${data.date}&qty=${data.qty}`);

// export const mobileNumberUpdateApiCall = (data) => api.create2(url.MOBILE_NO_UPDATE, data);

// export const generateOtp = (data) => api.create2(url.GENERATE_OTP, data);

// export const verifyOtp = (data) => api.create2(url.VERIFY_OTP, data);

// export const resendOtp = (data) => api.create2(url.RESEND_M_OTP, data);

// export const getBankMasterInfo = (data) => api.create4(url.BANK_MASTER_INFO, data);

// export const getBankMasterInfo2 = (data) => api.get(url.BANK_MASTER_INFO2 + `${data}`);

// export const pennyDrop = (data) => api.create(url.PENNY_DROP, data);

// export const checkModificationStatus = (data) => api.create2(url.MOD_STATUS, data);

// export const dpShameList = (data) => api.get2(url.DP_SHAME_LIST);

// export const emailAddressUpdateApiCall = (data) => api.create2(url.EMAIL_UPDATE, data);

// export const DDPIStatusUpdateApiCall = (data) => api.create2(url.DDPI_UPDATE, data);

// export const DDPIESign = (data) => api.create3(url.DDPI_ESIGN, data);

// export const AfterESignDeduction = (data) => api.create3(url.AFTER_E_SIGN, data);

// export const DDPIShameESign = (data) => api.create2(url.ALL_ESIGN, data);

// export const DPSchemeUpdateApiCall = (data) => api.create2(url.DP_SCHEME_UPDATE, data);

// export const PasswordUpdateApiCall = (data) => api.create2(url.PASSWORD_UPDATE, data);

// export const MpinUpdateApiCall = (data) => api.create2(url.MPIN_UPDATE, data);

// export const SegmentActivationApiCall = (data) => api.create3(url.SEGMENT_ACTIVATION, data);

// export const AddNomineeApiCall = (data) => api.create3(url.ADD_NOMINEE, data);

// export const AddBankApiCall = (data) => api.create3(url.ADD_BANK, data);

// export const BankMod = (data) => api.create2(url.BANK_MOD, data);

// export const Verify = (data) => api.create23(url.VERIFY, data);

// export const getClientIDApi = (data) => api.create2(url.GET_CLIENT_ID, data);

// export const getPledgeApi = (data) => api.get(url.PLEDGE + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${data.code}&tdate=${data.date}&mdate=${data.date}`);

// // Gets the logged in user data from local session
// export const getLoggedInUser = () => {
//   const user = localStorage.getItem("user");
//   if (user) return JSON.parse(user);
//   return null;
// };

// // //is user is logged in
// export const isUserAuthenticated = () => {
//   return getLoggedInUser() !== null;
// };

// // Register Method
// export const postFakeRegister = data => api.create(url.POST_FAKE_REGISTER, data);

// // Login Method
// export const postFakeLogin = data => api.create(url.POST_FAKE_LOGIN, data);

// // postForgetPwd
// export const postFakeForgetPwd = data => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// // Edit profile
// export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

// export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// // Register Method
// export const postJwtRegister = (url, data) => {
//   return api.create(url, data)
//     .catch(err => {
//       var message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message = "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "Invalid credentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };

// // Login Method
// export const postJwtLogin = data => api.create(url.POST_FAKE_JWT_LOGIN, data);

// // postForgetPwd
// export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// // postSocialLogin
// export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

// // Calendar
// // get Events
// export const getEvents = () => api.get(url.GET_EVENTS);

// // get Events
// export const getCategories = () => api.get(url.GET_CATEGORIES);

// // get Upcomming Events
// export const getUpCommingEvent = () => api.get(url.GET_UPCOMMINGEVENT);

// // add Events
// export const addNewEvent = event => api.create(url.ADD_NEW_EVENT, event);

// // update Event
// export const updateEvent = event => api.put(url.UPDATE_EVENT, event);

// // delete Event
// export const deleteEvent = event => api.delete(url.DELETE_EVENT, { headers: { event } });

// // Chat
// // get Contact
// export const getDirectContact = () => api.get(url.GET_DIRECT_CONTACT);

// // get Messages
// export const getMessages = roomId => api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// // add Message
// export const addMessage = message => api.create(url.ADD_MESSAGE, message);

// // add Message
// export const deleteMessage = message => api.delete(url.DELETE_MESSAGE, { headers: { message } });

// // get Channels
// export const getChannels = () => api.get(url.GET_CHANNELS);

// // MailBox
// //get Mail
// export const getMailDetails = () => api.get(url.GET_MAIL_DETAILS);

// // delete Mail
// export const deleteMail = forId => api.delete(url.DELETE_MAIL, { headers: { forId } });

// // Ecommerce
// // get Products
// export const getProducts = () => api.get(url.GET_PRODUCTS);

// // delete Product
// export const deleteProducts = product => api.delete(url.DELETE_PRODUCT + '/' + product);

// // add Products
// export const addNewProduct = product => api.create(url.ADD_NEW_PRODUCT, product);

// // update Products
// export const updateProduct = product => api.update(url.UPDATE_PRODUCT + '/' + product._id, product);

// // get Orders
// export const getOrders = () => api.get(url.GET_ORDERS);

// // add Order
// export const addNewOrder = order => api.create(url.ADD_NEW_ORDER, order);

// // update Order
// export const updateOrder = order => api.update(url.UPDATE_ORDER + '/' + order._id, order);

// // delete Order
// export const deleteOrder = order => api.delete(url.DELETE_ORDER + '/' + order);

// // get Customers
// export const getCustomers = () => api.get(url.GET_CUSTOMERS);

// // add Customers
// export const addNewCustomer = customer => api.create(url.ADD_NEW_CUSTOMER, customer);

// // update Customers
// export const updateCustomer = customer => api.update(url.UPDATE_CUSTOMER + '/' + customer._id, customer);

// // delete Customers
// export const deleteCustomer = customer => api.delete(url.DELETE_CUSTOMER + '/' + customer);

// // get Sellers
// export const getSellers = () => api.get(url.GET_SELLERS);

// // Project
// // get Project list
// export const getProjectList = () => api.get(url.GET_PROJECT_LIST);

// // Tasks
// // get Task
// export const getTaskList = () => api.get(url.GET_TASK_LIST);

// // add Task
// export const addNewTask = task => api.create(url.ADD_NEW_TASK, task);

// // update Task
// export const updateTask = task => api.update(url.UPDATE_TASK + '/' + task._id, task);

// // delete Task
// export const deleteTask = task => api.delete(url.DELETE_TASK + '/' + task);

// // CRM
// // get Contacts
// export const getContacts = () => api.get(url.GET_CONTACTS);

// // add Contact
// export const addNewContact = contact => api.create(url.ADD_NEW_CONTACT, contact);

// // update Contact
// export const updateContact = contact => api.update(url.UPDATE_CONTACT + '/' + contact._id, contact);

// // delete Contact
// export const deleteContact = contact => api.delete(url.DELETE_CONTACT + '/' + contact);

// // get Companies
// export const getCompanies = () => api.get(url.GET_COMPANIES);

// // add Companies
// export const addNewCompanies = company => api.create(url.ADD_NEW_COMPANIES, company);

// // update Companies
// export const updateCompanies = company => api.update(url.UPDATE_COMPANIES + '/' + company._id, company);

// // delete Companies
// export const deleteCompanies = company => api.delete(url.DELETE_COMPANIES + '/' + company);

// // get Deals
// export const getDeals = () => api.get(url.GET_DEALS);

// // get Leads
// export const getLeads = () => api.get(url.GET_LEADS);

// // add Lead
// export const addNewLead = lead => api.create(url.ADD_NEW_LEAD, lead);

// // update Lead
// export const updateLead = lead => api.update(url.UPDATE_LEAD + '/' + lead._id, lead);

// // delete Lead
// export const deleteLead = lead => api.delete(url.DELETE_LEAD + '/' + lead);

// // Crypto
// // Transation
// export const getTransationList = () => api.get(url.GET_TRANSACTION_LIST);

// // Order List
// export const getOrderList = () => api.get(url.GET_ORDRER_LIST);

// // Invoice
// //get Invoice
// export const getInvoices = () => api.get(url.GET_INVOICES);

// // add Invoice
// export const addNewInvoice = invoice => api.create(url.ADD_NEW_INVOICE, invoice);

// // update Invoice
// export const updateInvoice = invoice => api.update(url.UPDATE_INVOICE + '/' + invoice._id, invoice);

// // delete Invoice
// export const deleteInvoice = invoice => api.delete(url.DELETE_INVOICE + '/' + invoice);

// // Support Tickets
// // Tickets
// export const getTicketsList = () => api.get(url.GET_TICKETS_LIST);

// // add Tickets
// export const addNewTicket = ticket => api.create(url.ADD_NEW_TICKET, ticket);

// // update Tickets
// export const updateTicket = ticket => api.update(url.UPDATE_TICKET + '/' + ticket._id, ticket);

// // delete Tickets
// export const deleteTicket = ticket => api.delete(url.DELETE_TICKET + '/' + ticket);

// // Dashboard Analytics

// // Sessions by Countries
// export const getAllData = () => api.get(url.GET_ALL_DATA);
// export const getHalfYearlyData = () => api.get(url.GET_HALFYEARLY_DATA);
// export const getMonthlyData = () => api.get(url.GET_MONTHLY_DATA);

// // Audiences Metrics
// export const getAllAudiencesMetricsData = () => api.get(url.GET_ALLAUDIENCESMETRICS_DATA);
// export const getMonthlyAudiencesMetricsData = () => api.get(url.GET_MONTHLYAUDIENCESMETRICS_DATA);
// export const getHalfYearlyAudiencesMetricsData = () => api.get(url.GET_HALFYEARLYAUDIENCESMETRICS_DATA);
// export const getYearlyAudiencesMetricsData = () => api.get(url.GET_YEARLYAUDIENCESMETRICS_DATA);

// // Users by Device
// export const getTodayDeviceData = () => api.get(url.GET_TODAYDEVICE_DATA);
// export const getLastWeekDeviceData = () => api.get(url.GET_LASTWEEKDEVICE_DATA);
// export const getLastMonthDeviceData = () => api.get(url.GET_LASTMONTHDEVICE_DATA);
// export const getCurrentYearDeviceData = () => api.get(url.GET_CURRENTYEARDEVICE_DATA);

// // Audiences Sessions by Country
// export const getTodaySessionData = () => api.get(url.GET_TODAYSESSION_DATA);
// export const getLastWeekSessionData = () => api.get(url.GET_LASTWEEKSESSION_DATA);
// export const getLastMonthSessionData = () => api.get(url.GET_LASTMONTHSESSION_DATA);
// export const getCurrentYearSessionData = () => api.get(url.GET_CURRENTYEARSESSION_DATA);

// // Dashboard CRM

// // Balance Overview
// export const getTodayBalanceData = () => api.get(url.GET_TODAYBALANCE_DATA);
// export const getLastWeekBalanceData = () => api.get(url.GET_LASTWEEKBALANCE_DATA);
// export const getLastMonthBalanceData = () => api.get(url.GET_LASTMONTHBALANCE_DATA);
// export const getCurrentYearBalanceData = () => api.get(url.GET_CURRENTYEARBALANCE_DATA);

// // Dial Type
// export const getTodayDealData = () => api.get(url.GET_TODAYDEAL_DATA);
// export const getWeeklyDealData = () => api.get(url.GET_WEEKLYDEAL_DATA);
// export const getMonthlyDealData = () => api.get(url.GET_MONTHLYDEAL_DATA);
// export const getYearlyDealData = () => api.get(url.GET_YEARLYDEAL_DATA);

// // Sales Forecast
// export const getOctSalesData = () => api.get(url.GET_OCTSALES_DATA);
// export const getNovSalesData = () => api.get(url.GET_NOVSALES_DATA);
// export const getDecSalesData = () => api.get(url.GET_DECSALES_DATA);
// export const getJanSalesData = () => api.get(url.GET_JANSALES_DATA);

// // Dashboard Ecommerce
// // Revenue
// export const getAllRevenueData = () => api.get(url.GET_ALLREVENUE_DATA);
// export const getMonthRevenueData = () => api.get(url.GET_MONTHREVENUE_DATA);
// export const getHalfYearRevenueData = () => api.get(url.GET_HALFYEARREVENUE_DATA);
// export const getYearRevenueData = () => api.get(url.GET_YEARREVENUE_DATA);

// // Dashboard Crypto
// // Portfolio
// export const getBtcPortfolioData = () => api.get(url.GET_BTCPORTFOLIO_DATA);
// export const getUsdPortfolioData = () => api.get(url.GET_USDPORTFOLIO_DATA);
// export const getEuroPortfolioData = () => api.get(url.GET_EUROPORTFOLIO_DATA);

// // Market Graph
// export const getAllMarketData = () => api.get(url.GET_ALLMARKETDATA_DATA);
// export const getYearMarketData = () => api.get(url.GET_YEARMARKET_DATA);
// export const getMonthMarketData = () => api.get(url.GET_MONTHMARKET_DATA);
// export const getWeekMarketData = () => api.get(url.GET_WEEKMARKET_DATA);
// export const getHourMarketData = () => api.get(url.GET_HOURMARKET_DATA);

// // Dashboard Project
// // Project Overview
// export const getAllProjectData = () => api.get(url.GET_ALLPROJECT_DATA);
// export const getMonthProjectData = () => api.get(url.GET_MONTHPROJECT_DATA);
// export const gethalfYearProjectData = () => api.get(url.GET_HALFYEARPROJECT_DATA);
// export const getYearProjectData = () => api.get(url.GET_YEARPROJECT_DATA);

// // Project Status
// export const getAllProjectStatusData = () => api.get(url.GET_ALLPROJECTSTATUS_DATA);
// export const getWeekProjectStatusData = () => api.get(url.GET_WEEKPROJECTSTATUS_DATA);
// export const getMonthProjectStatusData = () => api.get(url.GET_MONTHPROJECTSTATUS_DATA);
// export const getQuarterProjectStatusData = () => api.get(url.GET_QUARTERPROJECTSTATUS_DATA);

// // Dashboard NFT
// // Marketplace
// export const getAllMarketplaceData = () => api.get(url.GET_ALLMARKETPLACE_DATA);
// export const getMonthMarketplaceData = () => api.get(url.GET_MONTHMARKETPLACE_DATA);
// export const gethalfYearMarketplaceData = () => api.get(url.GET_HALFYEARMARKETPLACE_DATA);
// export const getYearMarketplaceData = () => api.get(url.GET_YEARMARKETPLACE_DATA);

// // Project
// export const addProjectList = (project) => api.create(url.ADD_NEW_PROJECT, project);
// export const updateProjectList = (project) => api.put(url.UPDATE_PROJECT, project);
// export const deleteProjectList = (project) => api.delete(url.DELETE_PROJECT, { headers: { project } });

// // Pages > Team
// export const getTeamData = (team) => api.get(url.GET_TEAMDATA, team);
// export const deleteTeamData = (team) => api.delete(url.DELETE_TEAMDATA, { headers: { team } });
// export const addTeamData = (team) => api.create(url.ADD_NEW_TEAMDATA, team);
// export const updateTeamData = (team) => api.put(url.UPDATE_TEAMDATA, team);

// // File Manager

// // Folder
// export const getFolders = (folder) => api.get(url.GET_FOLDERS, folder);
// export const deleteFolder = (folder) => api.delete(url.DELETE_FOLDER, { headers: { folder } });
// export const addNewFolder = (folder) => api.create(url.ADD_NEW_FOLDER, folder);
// export const updateFolder = (folder) => api.put(url.UPDATE_FOLDER, folder);

// // File
// export const getFiles = (file) => api.get(url.GET_FILES, file);
// export const deleteFile = (file) => api.delete(url.DELETE_FILE, { headers: { file } });
// export const addNewFile = (file) => api.create(url.ADD_NEW_FILE, file);
// export const updateFile = (file) => api.put(url.UPDATE_FILE, file);

// // To Do
// export const getTodos = (todo) => api.get(url.GET_TODOS, todo);
// export const deleteTodo = (todo) => api.delete(url.DELETE_TODO, { headers: { todo } });
// export const addNewTodo = (todo) => api.create(url.ADD_NEW_TODO, todo);
// export const updateTodo = (todo) => api.put(url.UPDATE_TODO, todo);

// // To do Project
// export const getProjects = (project) => api.get(url.GET_PROJECTS, project);
// export const addNewProject = (project) => api.create(url.ADD_NEW_TODO_PROJECT, project);

// //Job Application
// export const getJobApplicationList = () => api.get(url.GET_APPLICATION_LIST);

// //API Key
// export const getAPIKey = () => api.get(url.GET_API_KEY);

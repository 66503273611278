import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import DeviceWidth from "../helper/DeviceWidth";
// const chartData1 = [
//   { name: 'Oct', Opened: 4, Traded: 0 },
//   { name: 'Oct', Opened: 4, Traded: 1 },
//   { name: 'Oct', Opened: 8, Traded: 0 },
//   { name: 'Oct', Opened: 10, Traded: 7 },
//   { name: 'Oct', Opened: 1, Traded: 0 },
// ]
const MonOpenTradedChart = ({ chartData, pathName }) => {
  const widthPX = DeviceWidth();
  let chartWidth;
  if (pathName === "/console") {
    chartWidth = widthPX - widthPX * 0.445;
  } else {
    chartWidth = widthPX - widthPX * 0.15;
  }
  const tooltipFormatter = (value, name) => {
    return parseFloat(value).toFixed(2).replace(/\.?0+$/, '');
  };
  const formatYAxisTicks = (value) => {
    return Math.floor(value) === value ? value : '';
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={formatYAxisTicks} domain={[0, 'auto']} />

        <Tooltip formatter={tooltipFormatter} />
        <Legend />
        <Bar dataKey="Opened" fill="#00FFC1" />
        <Bar dataKey="Traded" fill="#5367FC" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonOpenTradedChart;

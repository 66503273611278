import {
  GET_RECOMMENDED_ALGOS,
  API_RECOMMENDED_ALGOS_SUCCESS,
  API_RECOMMENDED_ALGOS_FAIL,
} from "./actionType";

export const algosApiResponseSuccess = (actionType, data) => ({
  type: API_RECOMMENDED_ALGOS_SUCCESS,
  payload: { actionType, data },
});

export const algosApiResponseError = (actionType, error) => ({
  type: API_RECOMMENDED_ALGOS_FAIL,
  payload: { actionType, error },
});

export const getRecommendedAlgos = (data) => ({
  type: GET_RECOMMENDED_ALGOS,
  payload: data,
});

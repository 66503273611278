import { put, takeEvery, all, fork } from "redux-saga/effects";

import { SET_USER_ID } from "./actionType";

function* storeUserId(action) {
  try {
    yield put({
      type: SET_USER_ID,
      payload: action.payload,
    });
  } catch (error) {
  }
}

export function* watchGetCart() {
  yield takeEvery(SET_USER_ID, storeUserId);
}

function* userIdSaga() {
  yield all([fork(watchGetCart)]);
}

export default userIdSaga;

import {
  GET_TOTAL_EARNING,
  API_TOTAL_EARNING_SUCCESS,
  API_TOTAL_EARNING_FAIL,
  EMPTY_TOTAL_EARNING,
} from "./actionType";

export const earningApiResponseSuccess = (actionType, data) => ({
  type: API_TOTAL_EARNING_SUCCESS,
  payload: { actionType, data },
});

export const earningApiResponseError = (actionType, error) => ({
  type: API_TOTAL_EARNING_FAIL,
  payload: { actionType, error },
});

export const getAllEarning = (data) => ({
  type: GET_TOTAL_EARNING,
  payload: data,
});

export const emptyTotalEarning = () => ({
  type: EMPTY_TOTAL_EARNING,
});
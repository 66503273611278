import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_LEAD_SUMMARY_CONV_UNCONV } from "./actionType";

import {
  leadSummaryApiResponseError,
  leadSummaryApiResponseSuccess,
} from "./action";

import { postLeadsSummaryConvUnconv } from "../../api/fakebackend_helper";

function* leadSummary(data) {
  try {
    const response = yield call(postLeadsSummaryConvUnconv, data.payload);

    yield put(
      leadSummaryApiResponseSuccess(GET_LEAD_SUMMARY_CONV_UNCONV, response)
    );
  } catch (error) {
    yield put(leadSummaryApiResponseError(GET_LEAD_SUMMARY_CONV_UNCONV, error));
  }
}

export function* watchGetCart() {
  yield takeEvery(GET_LEAD_SUMMARY_CONV_UNCONV, leadSummary);
}

function* LeadSummaryConvUnconvSaga() {
  yield all([fork(watchGetCart)]);
}

export default LeadSummaryConvUnconvSaga;

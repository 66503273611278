import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_INCOME_GET_CLIENT_LIST, GET_MONTHLY_OPNED_TRADE_LIST } from "./actionType";



import { ClientListApiResponseError, ClientListApiResponseSuccess, MonthlyOpnedTradeApiResponseError, MonthlyOpnedTradeApiResponseSuccess } from "./action";
import { postMonthlyOpnedTradeApi } from "../../api/fakebackend_helper";

function* MonthlyTradeOpned(data) {
    try {
        const response = yield call(postMonthlyOpnedTradeApi, data.payload);

        yield put(
            MonthlyOpnedTradeApiResponseSuccess(GET_MONTHLY_OPNED_TRADE_LIST, response)
        );
    } catch (error) {
        yield put(MonthlyOpnedTradeApiResponseError(GET_MONTHLY_OPNED_TRADE_LIST, error));
    }
}

export function* watchGetCart() {
    yield takeEvery(GET_MONTHLY_OPNED_TRADE_LIST, MonthlyTradeOpned);
}

function* MonthlyOpnedTradeSaga() {
    yield all([fork(watchGetCart)]);
}

export default MonthlyOpnedTradeSaga;

import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_INCOME_GET_CLIENT_LIST } from "./actionType";



import { ClientListApiResponseError, ClientListApiResponseSuccess } from "./action";
import { postBrokrageListIncomePage, postSubScriptionListIncomePage } from "../../../api/fakebackend_helper";

function* Brokrage(data) {
    try {
        const response = yield call(postSubScriptionListIncomePage, data.payload);

        yield put(
            ClientListApiResponseSuccess(GET_INCOME_GET_CLIENT_LIST, response)
        );
    } catch (error) {
        yield put(ClientListApiResponseError(GET_INCOME_GET_CLIENT_LIST, error));
    }
}

export function* watchGetCart() {
    yield takeEvery(GET_INCOME_GET_CLIENT_LIST, Brokrage);
}

function* ClientListSaga() {
    yield all([fork(watchGetCart)]);
}

export default ClientListSaga;

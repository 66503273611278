import {
  API_LOGIN_SUCCESS, API_LOGIN_FAIL, GET_LOGIN, REMOVE_LOGIN_DATA,
  START_LOADING,
  STOP_LOADING,
} from "./actionType";

// const data = localStorage.getItem("userData")
const INIT_STATE = {
  logingData: "",
  loginError: "",
  isLoading: false,
};

const logintDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case API_LOGIN_SUCCESS:
      switch (action.payload.actionType) {
        case GET_LOGIN:
          return {
            ...state,
            logingData: action.payload.data ? action.payload.data : [],

          };

        default:
          return { ...state };
      }
    case API_LOGIN_FAIL:
      switch (action.payload.actionType) {
        case GET_LOGIN:
          return {
            ...state,
            loginError: action.payload.error,
          };
        default:
          return { ...state };
      }
    case REMOVE_LOGIN_DATA:
      return {
        ...state,
        logingData: [],
      };
    case GET_LOGIN: {
      return {
        ...state,
      };
    }
    case STOP_LOADING:
      return { ...state, isLoading: false };

    default:
      return { ...state };
  }
};

export default logintDetails;

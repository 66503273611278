import * as React from "react";
import "./CustomPopup.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ImagePath } from "../helper/ImagePath";
import { WhatsappIcon, WhatsappShareButton } from "react-share";

const style = {
  position: "absolute",
  width: "100%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  maxWidth: '280px',
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px", // Adjusted to be on the right side
  cursor: "pointer",
  fontSize: "18px",
};

const CustomModal = ({ customModalFunction, modalValue, link, selectedPhoneNumber }) => {
  const message = "Check out Bigul – an easy-to-use, super fast trading app! Open your FREE demat and trading account in just minutes and enjoy FREE AMC for the first year. Start trading your favorite stocks effortlessly! Sign up now:";
  return (
    <div>
      <Modal
        open={modalValue}
        onClose={customModalFunction}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: 2 }}
          >
            Share on below options
          </Typography>
          <div className="d-flex justify-content-center android-os" style={{ cursor: "pointer" }}>
            <h6 onClick={() => customModalFunction()} style={closeButtonStyle}>
              X
            </h6>
            <WhatsappShareButton
              title={message}
              url={link}
              className="Demo__some-network__share-button me-2"
            >
              <WhatsappIcon size={37} round />
            </WhatsappShareButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomModal;
